export function getRegForm(forms) {
  // TODO: Somehow pick between multiple reg forms, eventually
  return forms['forms'].find(form => form['form_type'] === 'registration');
}

export function getOrderedPages(form) {
  return form['pages'].sort((a, b) => {return a.sort_order - b.sort_order});
}

export function getOrderedQuestions(page) {
  return page['questions'].sort((a, b) => {return a.sort_order - b.sort_order});
}

export function splitObjectField(attribute_id) {
  return attribute_id.split('.');
}

export function isMetaDataField(attribute_id) {
  const splitQuestionId = splitObjectField(attribute_id);
  return splitQuestionId.length > 2
}

export function getMetadataFieldValue(participant, field_slug) {
  let value = ''
  participant['metadata'].forEach(metadata => {
    if (metadata['field_slug'] === field_slug) {
      value = metadata['value'];
    }
  });
  return value
}

export function getFormikValue(attribute_id, formikProps) {
  const splitQuestionId = splitObjectField(attribute_id);
  if (isMetaDataField(attribute_id)) {
    // Metadata Field
    let formikPropsParticipant = formikProps.values["participant"];
    return formikPropsParticipant["metadata"][splitQuestionId[2]];
  } else {
    // Participant Field
    let formikPropsParticipant = formikProps.values["participant"];
    return formikPropsParticipant[splitQuestionId[1]];
  }
}

export function setFormikValue(value, attribute_id, formikProps) {
  const splitQuestionId = splitObjectField(attribute_id);
  if (isMetaDataField(attribute_id)) {
    // Metadata Field
    let formikPropsParticipant = formikProps.values["participant"];
    formikPropsParticipant["metadata"][splitQuestionId[2]] = value;
    formikProps.setFieldValue('participant', formikPropsParticipant);
  } else {
    // Participant Field
    let formikPropsParticipant = formikProps.values["participant"];
    formikPropsParticipant[splitQuestionId[1]] = value;
    formikProps.setFieldValue('participant', formikPropsParticipant);
  }
}

export function getFormikSurveyValue(attribute_id, formikProps) {
  const splitQuestionId = splitObjectField(attribute_id);
  let formikPropsParticipant = formikProps.values["survey"];
  return formikPropsParticipant[splitQuestionId[0]];
}

export function setFormikSurveyValue(value, attribute_id, formikProps) {
  const splitQuestionId = splitObjectField(attribute_id);
  let formikPropsParticipant = formikProps.values["survey"];
  formikPropsParticipant[[splitQuestionId[0]]] = value;
  formikProps.setFieldValue('survey', formikPropsParticipant);
}

export function isChildEnabled(question, formikProps) {
  switch (question.parent_child_options.field_requirement) {
    case "any":
      return checkChildAnyRelation(question.parent_child_options.fields, formikProps);
    case "all":
      return checkChildAllRelation(question.parent_child_options.fields, formikProps);
    default:
      return true;
  }
}

export function checkChildAnyRelation(fields, formikProps) {
  let passesRelation = false;
  fields.forEach(field => {
    let formikValue = getFormikValue(field.attribute_id, formikProps)
    if (formikValue === field.value) {
      passesRelation = true
    }
  });
  return passesRelation;
}

export function checkChildAllRelation(fields, formikProps) {
  let passesRelation = true;
  fields.forEach(field => {
    let formikValue = getFormikValue(field.attribute_id, formikProps)
    if (formikValue !== field.value) {
      passesRelation = false
    }
  });
  return passesRelation;
}

export function translateCSS(css) {
  // TODO: Implement css -> jsx styling
  let jsonCSS = {}
  if (css !== '') {
    try {
      jsonCSS = JSON.parse(css)
    } catch (e) {
      return {}
    }
  }
  return jsonCSS;
}

export function getInitialFormValues(participant) {
  let initialValues = {
    participant: getInitialRegistrationAnswers(participant)
  }
  initialValues["participant"]["metadata"] = getInitialMetadataValues(participant.metadata);
  return initialValues;
}

export function getInitialRegistrationAnswers(participant) {
  let registration_collection = participant.registration_answers;

  // Move base participant answers into reg block
  registration_collection["email"] = participant["email"];
  registration_collection["name_first"] = participant['name_first'];
  registration_collection["name_last"] = participant['name_last'];
  registration_collection["work_phone"] = participant['work_phone'];
  registration_collection["job_title"] = participant['job_title'];
  registration_collection["company"] = participant['company'];

  return registration_collection;
}

export function getInitialMetadataValues(metadata_block) {
  let metadata_collection = {}
  metadata_block.forEach(metadata => {
    metadata_collection[metadata.field_slug] = metadata.value;
  });
  return metadata_collection
}

export function getMetadataValue(field_name, metadata_block) {
  let field = metadata_block.find(metadata => metadata["field_slug"] === field_name)
  if (field) {
    return field["value"];
  }
  return '';
}