import React from "react";
import PropTypes from "prop-types";
class RegistrationPageSignifiers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  renderPageSignifiers() {
    const { page, regPages } = this.state;
    const { components } = this.props;
    if(components && components.pageSignifiers) {
      const PageSignifiersComponent = components['pageSignifiers'];
      return (<PageSignifiersComponent page={page} regPages={regPages}/>)
    }

  }

  render() {
    const { page, regPages } = this.props;
    // return (
    //   <div className="sg-reg-fb-page-signifiers col-xs-12">
    //     <img src={`/images/registration/p${page+1}.png`} />
    //   </div>
    // )
    if (regPages.length === page) return <></>;
    return (
      <div className="col-xs-12 registration-section-copy">
        <div style={{textAlign: "left"}} className="sg-registration-header-page-signifier-container">
          <img
            src={`/images/pages/page-${page + 1}.svg`}
            style={{height: "60px"}}
          />
        </div>
      </div>
    )
  }
}

export default RegistrationPageSignifiers;
