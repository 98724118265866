import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

class ProfileSwitcher extends React.Component {
  constructor(props) {
    super(props);

    const switcherValues = [
      {value: "0", label: "GTM (SumoYou)"},
      {value: "1", label: "non-GTM (SKO)"},
      {value: "2", label: "AM Group"},
      {value: "3", label: "PM Group"}
    ];

    let profileNumber = localStorage.getItem('profileNumber');

    this.state = {
      selectedSort: switcherValues[profileNumber]
    };
    this.rerenderForProfile = this.rerenderForProfile.bind(this);
  }

  getSortOptions() {
    return [
      {value: "0", label: "GTM (SumoYou)"},
      {value: "1", label: "non-GTM (SKO)"},
      {value: "2", label: "AM Group"},
      {value: "3", label: "PM Group"}
    ];
  }

  rerenderForProfile(profile) {
    localStorage.setItem("profileNumber", profile.value);
    this.setState({selectedSort: profile});
  }

  render() {
    const { selectedSort } = this.state;
    const DropdownIndicator = () => {
      return (
        <i className="fas fa-caret-right fa-lg" style={{color: "#64696C"}} />
      );
    };
    return (
      <div className="sg-header-time-switcher-select">
        <Select
          className=""
          options={this.getSortOptions()}
          classNamePrefix='sg-time-switcher'
          value={selectedSort}
          components={{ DropdownIndicator }}
          onChange={(option) => this.rerenderForProfile(option)}
        />
      </div>
    );
  }
}

export default ProfileSwitcher;
