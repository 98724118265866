import React from "react";
import PropTypes from "prop-types";
import axios from 'axios';

class AmberAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: ""
    };
    this.checkAlert = this.checkAlert.bind(this);
  }

  componentDidMount() {
    this.checkAlert();
    setInterval(this.checkAlert, 60000);
  }

  checkAlert() {
    axios.get('https://s3.amazonaws.com/www.jagcdn.net/SumoLogic/SKO21/amber_alert.json')
    .then(res => {
      let response = res.data;
      this.setState({response});
    })
    .catch(err => {
      console.log('Alert not found');
    });
  }

  renderAlert() {
    const { response } = this.state;
    return (
      <div className="sg-zoom-alert">
        <div className="container">
          <div className="row">
            <span>Attention!</span>
            &nbsp;Zoom is experiencing issues. Please join the webex meeting here: <a href={response.link} target="__blank">Join Here</a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { response } = this.state;
    if (response.status === "dead") {
      return (
        <>{this.renderAlert()}</>
      )
    }
    return <></>;
  }
}

export default AmberAlert;
