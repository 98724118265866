import React from "react";
import PropTypes from "prop-types";

class CustomReportTable extends React.Component {
  constructor(props) {
    super(props);
  }

  getReports(){
    return [
      {name:"Visa Report", url:"/admin/reports/visa_report"},
      {name:"Dietary Report", url:"/admin/reports/dietary_report"},
      {name:"Hotel Report", url:"/admin/reports/hotel_report"},
      {name:"Travel Report", url:"/admin/reports/travel_report"},
      // {name:"PAC Daily Analyst Report", url:"/admin/reports/analyst_complete_report"},
      // {name: "KBYG Visits Report", url:"/admin/reports/kbyg_visits"},
      // {name: "KBYG Clicks Report", url: "/admin/reports/kbyg_clicks"},
      // {name:"KBYG Email Activity Report", url: "/admin/reports/full_activity_report_by_tag?tag=kbyg"},
      // {name:"Survey Email Activity Report", url: "/admin/reports/full_activity_report_by_tag?tag=survey"},
      // {name:"Check In Report", url: "/admin/reports/check_in_report"}
    ]
  }

  renderReports() {
    return this.getReports().map(x=>
      this.renderReport(x)
    );
  }

  renderReport(report) {
    return (
      <div className="sg-admin-custom-report-container">
        <a
          href={report.url}
          target="_blank"
          className="sg-admin-custom-report-button"
        >
          <img src="/images/admin/custom-report-download.png"/>
        </a>
        <div className="sg-admin-custom-report-name">
          <a href={report.url} target="_blank">
            {report.name}
          </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="sg-admin-custom-report-table">
        {this.renderReports()}
      </div>
    )
  }

}

export default CustomReportTable;
