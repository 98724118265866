import React from "react";
import PropTypes from "prop-types";

class EmailSectionTile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { count, color, reportUrl } = this.props;
    return (
      <div className={`sg-email-section-tile sg-email-section-tile-${color}`}>
        {count}
        <a href={reportUrl} target="_blank">
          <div className="sg-email-section-tile-report">
            <img src="/images/admin/file-arrow-down.png" />
          </div>
        </a>
      </div>
    );
  }
}

export default EmailSectionTile;
