import React from "react";
import PropTypes from "prop-types";
import {
  getTotalNoms,
  getTotalNomsByNomStatus,
  getTotalNomsByInviteStatus,
  getByContactCompanySubSegment,
  getByContactCompanyRegion,
  getByContactCompanySalesCoverage
} from "@utility/NomToolUtils";
import NominationSnapshotTable from "./NominationSnapshotTable"

class NominationSnapshot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getSnapshotTableData() {
    const { nom_tool_nominations } = this.props;
    return [
      {
        title: "Enterprise",
        rows: [
          { value: getByContactCompanySubSegment(nom_tool_nominations, "GES SOUTH"), label: "GES South" },
          { value: getByContactCompanySubSegment(nom_tool_nominations, "GES EAST"), label: "GES East" },
          { value: getByContactCompanySubSegment(nom_tool_nominations, "GES CENTRAL"), label: "GES Central" },
          { value: getByContactCompanySubSegment(nom_tool_nominations, "GES WEST"), label: "GES West" },
          { value: getByContactCompanySubSegment(nom_tool_nominations, "PREMIER"), label: "Premier" },
        ]
      },
      {
        title: "US Commercial",
        rows: [
          { value: getByContactCompanySubSegment(nom_tool_nominations, "COMMERCIAL EAST AREA"), label: "East" },
          { value: getByContactCompanySubSegment(nom_tool_nominations, "COMMERCIAL WEST AREA"), label: "West" },
          { value: getByContactCompanySubSegment(nom_tool_nominations, "COMMERCIAL CENTRAL AREA"), label: "Central" },
        ]
      },
      {
        title: "Service Provider",
        rows: [
          { value: getByContactCompanySalesCoverage(nom_tool_nominations, "SERVICE PROVIDER"), label: "TBD" },
          { value: 0, label: "TBD" },
          { value: 0, label: "TBD" },
        ]
      },
      {
        title: "Public Sector",
        rows: [
          { value: getByContactCompanySalesCoverage(nom_tool_nominations, "PUBLIC SECTOR"), label: "Federal" },
          { value: 0, label: "East" },
          { value: 0, label: "West" },
        ]
      },
      {
        title: "Non-US",
        rows: [
          { value: getByContactCompanyRegion(nom_tool_nominations, "EMEAR"), label: "EMEA" },
          { value: getByContactCompanyRegion(nom_tool_nominations, "LATAM"), label: "LATAM" },
          { value: getByContactCompanyRegion(nom_tool_nominations, "APJC"), label: "APJC" },
        ]
      }
    ];
  }

  renderTables() {
    const { nom_tool_nominations } = this.props;
    return this.getSnapshotTableData().map(data =>
      <NominationSnapshotTable nom_tool_nominations={nom_tool_nominations} data={data} />
    );
  }

  getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    return today;
  }

  render() {
    const { nom_tool_nominations } = this.props;
    // console.log(nom_tool_nominations);
    // nom_tool_nominations.forEach(nom => {
    //   if (nom.contact.company.sales_coverage === "PUBLIC SECTOR") {
    //     console.log(nom)
    //   }
    // });
    return (
      <div className="row equal">
        <div className="col-xs-12">
          <div className="snapshot-title">Cisco CIO Exchange Nomination Snapshot</div>
          <div className="snapshot-description"><b>{`${getTotalNoms(nom_tool_nominations)}`}</b> Total Customers Nominated - As of {this.getDate()}</div>
        </div>
        {this.renderTables()}
      </div>
    );
  }
}

export default NominationSnapshot;
