import React from "react";
import PropTypes from "prop-types";

class KbygAccomodationsExtraHeader extends React.Component {
  constructor(props) {
    super(props);
    this.formatDate = this.formatDate.bind(this);
  }

  formatDate(date) {
    switch(date) {
      case("november-10"):
        return "11-10-2024"
      case("november-11"):
        return "11-11-2024"
      case("november-12"):
        return "11-12-2024"
      case("november-13"):
        return "11-13-2024"
      case("november-14"):
        return "11-14-2024"
      case("november-15"):
        return "11-15-2024"  
      default:
        return "";
    }
  }

  render() {
    let { booking } = this.props;
    booking = booking || {}
    if(booking) {
      return (
        <div className="sg-kbyg-accomodations-extra-header">
          <br/>
          <div className="sg-kbyg-body">
            Check-in Date:
          </div>
          <input className="form-control" disabled={true} value={this.formatDate(booking['hotel_arrival_date'])}/>
          <br/>


          <div className="sg-kbyg-body">
            Check-Out Date:
          </div>
          <input className="form-control" disabled={true} value={this.formatDate(booking['hotel_departure_date'])}/>
          <br/>


          <div className="sg-kbyg-body">
            Hotel Confirmation Number:
          </div>
          <input className="form-control" disabled={true} value={booking['hotel_confirmation_number']||""}/>
          <br/>

        </div>
      )
    }
    return ""
  }
}

export default KbygAccomodationsExtraHeader;
