import React from "react";
import { fetchAPI } from "@utility/NetworkUtils";
class CookieModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    }
    this.acceptCookies=this.acceptCookies.bind(this);
    this.declineCookies=this.declineCookies.bind(this);
  }
  declineCookies(){
    fetchAPI("/cookies?cookies=false", (json) => {
      this.setState({showModal: false});
    },
    {
      method: 'POST',
    })
  }

  acceptCookies() {
    fetchAPI("/cookies?cookies=true", (json) => {
      this.setState({showModal: false});
    },
    {
      method: 'POST',
    })
  }

  render() {
    const { showModal } = this.state;
    if(showModal) {
      return (
        <div className="cookie-modal-wrapper">
          <section className="cookies-modal">
            <div className="cookies-text">
              By continuing to use our website, you acknowledge the use of cookies.&nbsp;
              <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html" target="_blank">Privacy Statement</a>
              </div>
              <div className="cookies-button-wrapper">
              <a onClick={this.acceptCookies}><button type="button" className="cookies-button">
              Accept
              </button>
              </a><a onClick={this.declineCookies}><button type="button" className="cookies-button">
              Decline
              </button>
            </a></div>
          </section>
        </div>
      );
    }
    return <></>;
  }
}

export default CookieModal;