import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"
import UserModal from "./UserModal"
import Select from 'react-select'
import DeactivateUserModal from "./DeactivateUserModal"
class UsersDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      users: props.users,
      searchType: null,
    }
    this.deactivateUser = this.deactivateUser.bind(this);
  }

  renderUsers(){
    return this.getUsersFiltered().map(x=> this.renderUser(x));
  }

  renderUser(user) {
    const {attendees} = this.props;
    const attendee = user.attendee
    return (
      <tr className={`sg-admin-user-row ${user.deleted_at ? "sg-admin-user-row-deleted" : ""}`}>
        <td className="text-center">
          {
            !user.deleted_at &&
            this.props.user.email.includes("@jaguardesignstudio.com") ? (
            <a href={`/admin/users/become_user/${user.id}`}><button className="sg-admin-user-shift-button" > User Shift </button></a>
          ):""}
        </td>
        <td>
          <b>{attendee ? `${attendee.name_first} ${attendee.name_last}` : "Unavailable"}</b>
        </td>
        <td>
          {attendee ? attendee.job_title || "" : ""}
        </td>
        <td style={{wordBreak: "break-all"}}>
          <UserModal attendee={attendee} user={user}>
            {user.email}
          </UserModal>
        </td>
        <td>
          {user.deleted_at ?
            (<a href="#" onClick={() => this.reactivateUser(user.id)}>Reactivate</a> ) : (<DeactivateUserModal user={user} deactivateUser={this.deactivateUser}><a href="#">Deactivate</a></DeactivateUserModal> )
          }
        </td>
      </tr>
    )
  }

  getUsersFiltered() {
    let { attendees } = this.props;
    let { searchQuery, users, searchType } = this.state;
    users = users.map(user => {
      const attendee = attendees.find(x=> x.email.toLowerCase() == user.email.toLowerCase());
      user.attendee = attendee;
      return user;
    });

    users = users.filter(user => {
      const attendee = user.attendee;
      if(user.email.toLowerCase().includes(searchQuery.toLowerCase())){
        return true;
      }
      if(attendee) {
        if(attendee.name_last.toLowerCase().includes(searchQuery.toLowerCase()) || attendee.name_first.toLowerCase().includes(searchQuery.toLowerCase()))
        {
          return true;
        }
      }
      return false
    })

    if(searchType =="active") {
      users = users.filter(x=>x.deleted_at == null)
    }else if(searchType=="deactivated") {
      users = users.filter(x=>x.deleted_at != null)
    }

    users = users.sort((a,b) => a.email > b.email ? 1 : -1)
    return users
  }

  reactivateUser(id) {
    const { users } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/admin/users/${id}/reactivate`, {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {}
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.user) {
        this.setState({
          users: users.filter(x=>x.id !=json.user.id).concat(json.user),
        })
      }
    });
  }

  deactivateUser(id) {
    const { users } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/admin/users/${id}/deactivate`, {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {}
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.user) {
        this.setState({
          users: users.filter(x=>x.id !=json.user.id).concat(json.user),
        })
      }
    });
  }

  renderSelect() {
    const {sessions} = this.props;
    const { searchType } = this.state;
    const options = [
      {label: "All", value: null},
      {label: "Active", value: "active"},
      {label: "Deactivated", value: "deactivated"}
    ]
    const value = options.find(x=>x.value == searchType);
    return (
      <div className="sg-admin-select">
        <Select
          options={options}
          value={value ? value : {value: "", label:""}}
          onChange={(option) => {
            this.setState({
              searchType: option.value,
            })
          }}
        />
      </div>
    )
  }

  render() {
    const { searchQuery } = this.state;
    return (
      <>
      <div className="row sg-platform-report-section">
        <div className="row">
          <div className="sg-admin-users-page-title">
            Manage Users
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            Search
            <br />
            <input className="form-control" value={searchQuery} onChange={(e)=>this.setState({searchQuery: e.target.value})}/>
            <br />
          </div>
          <div className="col-xs-12 col-md-2">
            Filter by Status
            <br />
            {this.renderSelect()}
            <br />
          </div>
        </div>
          <table className="sg-admin-user-panel-table">
            <tr>
              <th className="text-center">
                Become User
              </th>
              <th>
                Name
              </th>
              <th>
                Job Title
              </th>
              <th>
                Email
              </th>
              <th>
                Action
              </th>
            </tr>
            {this.renderUsers()}
          </table>
        </div>
      </>
    );
  }
}

export default UsersDashboard;
