import React from "react";
import PropTypes from "prop-types";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
class MailActivityReports extends React.Component {

  renderReport(email) {
    const report_url = `/admin/reports/individual_mail_report?gid=${email.gid}`
    return (
      <tr>
        <td>
          <a
            href={report_url}
            target="_blank"
            className="sg-admin-custom-report-button"
          >
            <img src="/images/admin/custom-report-download.png"/>
          </a>
        </td>
        <td>
          {email.name||"(No Name Given)"}
        </td>
        <td>
          {email.template_name}
        </td>
        <td>
          {email.list_name}
        </td>
        <td>
          {formatToTimeZone(email.completed_timestamp, 'MM-DD-YYYY HH:mm aa',{timeZone: 'America/Los_Angeles'})}
        </td>

      </tr>
    )
  }

  getFilteredEmails() {
    const { sent_emails } = this.props;
    return (sent_emails.emails||[]).filter(x=>
      (x.name||"").toLowerCase().indexOf("test") == -1
    ).filter(x=>
      (x.template_name||"").toLowerCase().indexOf("test") == -1
    ).filter(x=>
      new Date(x.completed_timestamp) > new Date("2023-01-01")
    ).filter(x=>
      (x.list_name||"").toLowerCase().indexOf("test") == -1
    ).filter(x=>
      !x.archived
    ).filter(x=>
      x.email_type=="broadcast"
    ).sort((a,b) => new Date(a.completed_timestamp) < new Date(b.completed_timestamp) ? -1 : 1);
  }

  renderReports() {
    return this.getFilteredEmails().map(x=>
      this.renderReport(x)
    )
  }

  renderHeader() {
    return (
      <thead>
        <tr>
          <th></th>
          <th>
            Email Name
          </th>
          <th>
            Email template
          </th>
          <th>
            Email list
          </th>
          <th>
            Completed timestamp
          </th>
        </tr>
      </thead>
    )
  }

  render() {
    return (
      <table className="sg-admin-custom-report-table">
        {this.renderHeader()}
        <tbody>
        {this.renderReports()}
        </tbody>
      </table>
    )
  }

}

export default MailActivityReports;
