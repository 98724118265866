import React from "react";
import {FormBuilder} from "@jaguardesignstudio/silentgrowl-form-builder-react";
import RegistrationPageSignifiers from "./RegistrationPageSignifiers";
import RegistrationRadioField from "./RegistrationRadioField";
import RegistrationTimeField from "./RegistrationTimeField";
import RegistrationDropdownField from "./RegistrationDropdownField";
import RegistrationCheckBoxField from "./RegistrationCheckBoxField";

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('reg submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    const { participant } = this.props;
    return [
      { key: '*|EMAIL|*', value: participant.email },
    ]
  }

  render() {
    const { ticket_info, participant, form } = this.props;
    return (
      <FormBuilder
        key="registration-form"
        components={{
          pageSignifiers: RegistrationPageSignifiers,
          radiofield: RegistrationRadioField,
          datefield: RegistrationTimeField,
          selectfield: RegistrationDropdownField,
          checkboxfield: RegistrationCheckBoxField
        }}
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/registration'}
        submitCallback={this.callback}
        type={'registration'}
        hide_test={false}
      />
    );
  }
}

export default RegistrationForm;
