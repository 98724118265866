import React from "react";
import PropTypes from "prop-types";

class CheckSwitchers extends React.Component {
  constructor(props) {
    super(props);
  }

  resetSwitchers() {
    localStorage.removeItem('dayNumber');
    localStorage.removeItem('time');
    localStorage.removeItem('profileNumber');
    return "";
  }

  render() {
    return (
      <>{this.resetSwitchers()}</>
    );
  }
}

export default CheckSwitchers;
