import React from "react";
import PropTypes from "prop-types";


class JoinZoomMeeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return <></>;
  }
}

export default JoinZoomMeeting;
