
export function isUserGTM(isGTM) {
  const profileOverrideNumber = localStorage.getItem('profileNumber');
  if (profileOverrideNumber === null) {
    return isGTM;
  }
  if (profileOverrideNumber === "0") {
    return true;
  }
  return false;
}
