import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { formatToTimeZone } from 'date-fns-timezone';
class DateSwitcher extends React.Component {
  constructor(props) {
    super(props);
    const { times } = props;
    this.switcherValues = [
      {value: "0", label: `Pre-show (${formatToTimeZone(new Date(times.week_one_day_one), "MMM D", { timeZone: 'America/Los_Angeles' })})`},
      {value: "1", label: `Day One (${formatToTimeZone(new Date(times.week_one_day_two), "MMM D", { timeZone: 'America/Los_Angeles' })})`},
      {value: "2", label: `Day Two (${formatToTimeZone(new Date(times.week_one_day_one), "MMM D", { timeZone: 'America/Los_Angeles' })})`},
      {value: "3", label: `Post Show (${formatToTimeZone(new Date(times.week_one_day_one), "MMM D", { timeZone: 'America/Los_Angeles' })})`}
    ];
    this.state = {
      selectedDay: localStorage.getItem('dayNumber')
    }
  }

  rerenderForDay(e) {
    const { toggleClock } = this.props;
    localStorage.setItem("dayNumber", e.target.value);
    toggleClock();
    this.setState({ selectedDay: e.target.value });
  }

  render() {
    const { selectedDay } = this.state;
    return (
      <div className="sg-header-time-switcher-select" style={{margin: "5px 0px"}}>
        <input type="date" min="2021-02-21" max="2021-03-06" onChange={(e) => this.rerenderForDay(e)} value={selectedDay} />
      </div>
    );
  }
}

export default DateSwitcher;
