import React from "react";
import PropTypes from "prop-types";
import TicketingCheckinForm from "./TicketingCheckinForm"
import Modal from "react-modal";

class TicketingCheckInModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      activated: false,
      attemptActivationCode: "",
      error: null,
    }
      this.toggleModal = this.toggleModal.bind(this);
      this.savedFormCallback = this.savedFormCallback.bind(this);
  }

  toggleModal(e) {
    const { modalOpen } = this.state;
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({
      modalOpen: !modalOpen,
    });
  }

  renderModal() {
    const { modalOpen } = this.state;
    if(modalOpen) {
      return (
        <TicketingCheckinForm
          {...this.props}
          savedFormCallback={this.savedFormCallback}
        />
      )
    }
  }

  savedFormCallback(attendees) {
    const { updateParticipantCallback } = this.props;
    if(updateParticipantCallback) {
      updateParticipantCallback(attendees);
    }
    this.setState({
      modalOpen:false,
      error:null
    })
  }

  render() {
    const { modalOpen } = this.state;
    const { user, participant, outerClassNames, outerStyle, children} = this.props;
    return (
      <>
        <div className={outerClassNames} style={{...(outerStyle||{})}} onClick={(e) => this.toggleModal(e)} >
          {children}
        </div>
        <Modal
          className="white-background cnt-user-admin-modal cnt-user-admin-modal-large"
          overlayClassName="cnt-default-layout-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <>
            <img className="cnt-admin-user-management-close" src="/images/admin/ticketing-modal-close.svg" onClick={this.toggleModal}/>
            {this.renderModal()}
          </>
        </Modal>
      </>
    );
  }
}

export default TicketingCheckInModal;
