import React from "react";
import PropTypes from "prop-types";

class SwitcherReset extends React.Component {
  constructor(props) {
    super(props);
  }

  resetSwitchers() {
    localStorage.removeItem('dayNumber');
    localStorage.removeItem('time');
    localStorage.removeItem('profileNumber');
    location.reload();
  }

  render() {
    return (
      <div className="sg-header-reset" onClick={this.resetSwitchers}>
        Reset
      </div>
    );
  }
}

export default SwitcherReset;
