import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";

class DeactivateUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.deactivateUserFromModal = this.deactivateUserFromModal.bind(this);
  }

  renderModal() {
    const { user, attendee} = this.props;
    return (
      <div className="row">
        <div className="col-xs-12 text-center">
          <div className="sg-admin-user-modal-title">
            Are you sure you want to deactivate this user?
          </div>
          <div className="sg-admin-user-modal-description">
            User access will be revoked.
          </div>
          <div onClick={this.deactivateUserFromModal}className="sg-admin-user-modal-button">
            Yes, deactivate
          </div>
          <div onClick={this.toggleModal} className="sg-admin-user-modal-cancel-button">
            Cancel
          </div>
        </div>
      </div>
    );
  }

  deactivateUserFromModal() {
    const {deactivateUser, user} = this.props;
    deactivateUser(user.id)
    this.setState({
      modalOpen: false
    })
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block"}} onClick={() => this.toggleModal()} >
          {children}
        </div>

        <Modal
          className="white-background sg-speaker-modal disable-scrollbars sg-admin-user-modal"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <div className="sg-overlay-close-button" onClick={this.toggleModal}> &#10005; </div>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default DeactivateUserModal;
