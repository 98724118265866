import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, listTimeZones } from 'date-fns-timezone';
class KbygPersonalAgenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDates: []
    }
  }

  renderDays() {
    const days = this.getDays();
    return days.map(x=>
      this.renderDay(x)
    )
  }

  toggleDateOpen(e, date) {
    e.stopPropagation();
    const { openDates } = this.state;
    const isOpen = openDates.indexOf(date) != -1;
    if(isOpen) {
      this.setState({
        openDates: openDates.filter(x=>x!=date)
      })
    }else {
      this.setState({
        openDates: [...openDates, date]
      })
    }
  }

  renderDay(date) {
    const { schedule } = this.props;
    const { openDates } = this.state;
    const real_date = new Date(date+"T00:01:00Z");
    const isOpen = openDates.indexOf(date) != -1;
    // const sessions =(newSessions.filter(x=>x.date == date)||[]).sort((a,b)=> new Date(b.date+"T"+b.start_time_raw) < new Date(a.date+"T"+a.start_time_raw) ? 1 : -1);
    const sessions =schedule.attendee.sessions.filter(
      x=>x.date == date
    ).sort((a,b)=> new Date(b.date+"T"+b.start_time_raw) < new Date(a.date+"T"+a.start_time_raw) ? 1 : -1);
    return (
      <div className={`sg-kbyg-personal-agenda-schedule-day ${isOpen ? 'open' : 'closed'}`}>
        <div className={`sg-kbyg-personal-agenda-schedule-day-title`} onClick={(e)=>this.toggleDateOpen(e, date)}>
          <b>{formatToTimeZone(real_date,"dddd, MMMM D", {timeZone: 'Australia/Melbourne'})}</b>
          <div className="sg-kbyg-personal-agenda-header-signfier" onClick={(e)=>this.toggleDateOpen(e, date)}>
            <img src="/images/kbyg/Chevron_Mobile.svg"/>
          </div>
        </div>
  
        <div className="sg-kbyg-personal-agenda-sessions-container">
          {sessions.map(x=>this.renderSession(x))}
        </div>
        <div className={`sg-kbyg-personal-agenda-schedule-day-divider`}/>
        
      </div>
    )
  }

  renderSession(session) {
    return ( 
      <div className="sg-kbyg-personal-agenda-session sg-kbyg-body">
        <div className="sg-kbyg-personal-agenda-session-time">
          {session.start_time_formatted.replace("am", "a.m.").replace("pm", "p.m.")} - {session.end_time_formatted.replace("am", "a.m.").replace("pm", "p.m.")}
        </div>
        <div className="sg-kbyg-personal-agenda-session-name sg-kbyg-body-bold">
          {session.title}
        </div>
        <div className="sg-kbyg-personal-agenda-session-description" dangerouslySetInnerHTML={{ __html: session.description.replaceAll("\n", "<br/>") }}/>
      </div>
    )
  }

  getDays() {
    const { cms_section } = this.props;
    try {
      const options = JSON.parse(cms_section.answers.options);
      return options['dates']||[]
    }
    catch(e){
      console.log(e)
      return []
    }
  }

  render() {
    const { schedule } = this.props;
    if(schedule && schedule.attendee) {
      const { attendee } = schedule;
      return (
        <div className="sg-kbyg-personal-agenda-schedule-container">
          {/* <div className="sg-kbyg-personal-agenda-schedule-download-section text-right">
            <a href={`https://live24.ciscoevents.net/reports/attendees/${attendee.id}`} target="_blank">
              <button className="sg-round-button" style={{backgroundColor: "#0D274D"}}>
                Download Agenda
              </button>
            </a>
          </div> */}
          {this.renderDays()}
        </div>
      )
    }
    return ""
  }
}

export default KbygPersonalAgenda;
