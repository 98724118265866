import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import   { formatToTimeZone } from 'date-fns-timezone';
class TimeSwitcher extends React.Component {
  constructor(props) {
    super(props);
    const { times } = props;

    this.switcherValues = [
      {value: "0", label: `Pre-show (${formatToTimeZone(new Date(times.day_one_start), "MMM D", { timeZone: 'America/Los_Angeles' })})`},
      {value: "1", label: `Day One (${formatToTimeZone(new Date(times.day_two_start), "MMM D", { timeZone: 'America/Los_Angeles' })})`},
      {value: "2", label: `Day Two (${formatToTimeZone(new Date(times.day_three_start), "MMM D", { timeZone: 'America/Los_Angeles' })})`},
      {value: "3", label: `Post Show (${formatToTimeZone(new Date(times.day_four_start), "MMM D", { timeZone: 'America/Los_Angeles' })})`}
    ];

    let dayNumber = localStorage.getItem('dayNumber');
    this.state = {
      selectedSort: this.switcherValues[dayNumber]
    };
    this.rerenderForDay = this.rerenderForDay.bind(this);
  }

  getSortOptions() {
    return this.switcherValues;
  }

  rerenderForDay(day) {
    const { toggleClock } = this.props;
    localStorage.setItem("dayNumber", day.value);
    this.setState({
      selectedSort: this.getSortOptions()[day.value]
    });
    toggleClock();
  }

  render() {
    const { selectedSort } = this.state;
    const DropdownIndicator = () => {
      return (
        <i className="fas fa-caret-right fa-lg" style={{color: "#64696C"}} />
      );
    };
    return (
      <div className="sg-header-time-switcher-select">
        <Select
          className=""
          options={this.getSortOptions()}
          classNamePrefix='sg-time-switcher'
          value={selectedSort}
          components={{ DropdownIndicator }}
          onChange={(option) => this.rerenderForDay(option)}
        />
      </div>
    );
  }
}

export default TimeSwitcher;
