export function participantMailMapping(participants, email) {
  const emailSends = email.sends
  const emailBounces = email.bounces
  const emailClicks = email.clicks
  const emailOpens = email.opens
  const stdUsers = [];
  console.log({emailOpens});
  console.log({email});
  const returnedParticipants = participants.map(participant => {
    const participantEmail = participant.email.toLowerCase().trim();
    let participantBounces = emailBounces.filter(std => participantEmail == std["Email"].toLowerCase().trim());
    let participantEmailResponses = emailSends.filter(response => {
      return response["Recipients"].map(x=>x.toLowerCase()).indexOf(participantEmail) != -1
    });

    const participantEmailMessageIds= participantEmailResponses.map(x=>x.MessageID);
    let participantEmailClicks = emailClicks.filter(response => {
      return response["Recipient"].toLowerCase().trim() == participantEmail && participantEmailMessageIds.indexOf(response['MessageID']) != -1
    });
    let participantEmailOpens = emailOpens.filter(response => {
      return response["Recipient"].toLowerCase().trim() == participantEmail && participantEmailMessageIds.indexOf(response['MessageID']) != -1
    });
    return {
      loweredEmail:participantEmail,
      opens: participantEmailOpens,
      email: participant.email,
      name_first: participant.name_first,
      name_last: participant.name_last,
      bounced: participantBounces.length > 0,
      bounces: participantBounces,
      sent: participantEmailResponses.length > 0,
      clicked: participantEmailClicks.length > 0,
      opened: participantEmailOpens.length > 0,
      status: participantEmailResponses.length > 0 ? participantEmailResponses[0]["Status"] : null,
      opt_out: participant.opt_out ? "True" : "False"
    }
  });
  return returnedParticipants;
}
