import React from "react";
import FormBuilder from "../forms/FormBuilder";

class SurveysFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('survey submit complete');
    console.log(json);
  }

  render() {
    const { ticket_info, participant, form } = this.props;
    return (
      <FormBuilder
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        submitUrl={'/surveys'}
        submitCallback={this.callback}
        type={'survey'}
      />
    );
  }
}

export default SurveysFormWrapper;
