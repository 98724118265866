import React from 'react';
import Modal from 'react-modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { fetchAPI } from "../../utility/NetworkUtils";

class AddAdminSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      deleteModalOpen: false,
      editModalOpen: false,
      selectedDeleteEmail: "",
      selectedEditEmail: "",
      email: "",
      password: "",
      editPassword: "",
      adminEmails: [],
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.renderAdmins = this.renderAdmins.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.deleteAdmin = this.deleteAdmin.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
  }

  componentDidMount() {
    this.getAdmins()
  }

  getAdmins() {
    fetchAPI("/admin/admin_emails", (json) => {
      this.setState({adminEmails: json.admins});
    },
    {
      method: 'GET',
    })
  }

  deleteAdmin() {
    const { selectedDeleteEmail } = this.state;
    fetchAPI("/admin/delete", (json) => {
      this.getAdmins();
      this.toggleDeleteModal('');
    },
    {
      method: 'POST',
      body: {
        email: selectedDeleteEmail
      }
    })
  }
  
  toggleDeleteModal(email) {
    const { deleteModalOpen } = this.state;
    if (deleteModalOpen) {
      this.setState({ deleteModalOpen: false, selectedDeleteEmail: "" });
    } else {
      this.setState({ deleteModalOpen: true, selectedDeleteEmail: email });
    }
  }

  toggleEditModal(email) {
    const { editModalOpen } = this.state;
    if (editModalOpen) {
      this.setState({ editModalOpen: false, selectedEditEmail: "" });
    } else {
      this.setState({ editModalOpen: true, selectedEditEmail: email });
    }
  }

  renderAdmins() {
    const { user_email } = this.props;
    const {adminEmails} = this.state;
    return(
      <div className="sg-admin-list-container">
        {
          adminEmails.map((email) => 
            <div className="sg-admin-list-entry">
              <div className="sg-admin-list-entry-text">
                {email}
              </div>
              {
                email.includes('@jaguardesignstudio') ?
                <></>
                :
                <div className="sg-admin-list-entry-control">
                  { user_email.includes('@jaguardesignstudio') ? <a style={{cursor: "pointer", marginRight: "10px"}} onClick={() => {this.toggleEditModal(email)}}>Edit</a> : <></> }
                  <a style={{cursor: "pointer"}} onClick={() => {this.toggleDeleteModal(email)}}>Delete</a>
                </div>
              }
            </div>
          )
        }
      </div>
    )
  }

  renderModal() {
    const {email, password} = this.state;
    return(
      <div>
        <h3>
          Add an Admin
        </h3>
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={() => {
            const errors = {};
            if (!email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (!password) {
              errors.password = "Required"
            } else if (password.length < 8) {
              errors.password = "Password must be at least 8 characters long"
            }
            return errors;
          }}
        >
          <Form className="sg-add-admin-modal-form" onSubmit={this.handleSubmit.bind(this)}>
            <label>
              Email
            </label>
            <Field className="form-control" type="email" name="email" value={email} onChange={(e) => this.setState({email: e.target.value})}/>
            <ErrorMessage name="email" component="div" />
            <label className="sg-add-admin-modal-password-label">
              Password
            </label>
            <Field className="form-control" type="password" name="password" value={password} onChange={(e) => this.setState({password: e.target.value})}/>
            <ErrorMessage name="password" component="div" />
            <button type="submit" className="sg-add-admin-modal-submit-button">
              Submit
            </button>
          </Form>
        </Formik>
      </div>
    )
  }

  renderDeleteModal() {
    const { selectedDeleteEmail } = this.state;
    return (
      <div>
        <label style={{fontWeight: "300"}}>
          Are you sure you want to delete <b>{selectedDeleteEmail}</b>?
        </label>
        <br />
        <button className="sg-add-admin-modal-submit-button" onClick={this.deleteAdmin}>
          Delete
        </button>
        <button className="sg-add-admin-modal-cancel-button" style={{marginLeft: "10px"}} onClick={this.toggleDeleteModal}>
          Cancel
        </button>
      </div>
    );
  }

  renderEditModal() {
    const { selectedEditEmail, editPassword } = this.state;
    return (
      <div>
        <label style={{fontWeight: "300"}}>
          Enter a new password for <b>{selectedEditEmail}</b>.
        </label>
        <Formik
          initialValues={{ email: selectedEditEmail, editPassword: '' }}
          validate={() => {
            const errors = {};
            if (!editPassword) {
              errors.editPassword = "Required"
            } else if (editPassword.length < 8) {
              errors.editPassword = "Password must be at least 8 characters long"
            }
            return errors;
          }}
        >
          <Form className="sg-add-admin-modal-form" onSubmit={this.handleEditSubmit.bind(this)}>
            <label>
              Email
            </label>
            <Field disabled className="form-control" type="email" name="email" value={selectedEditEmail} />
            <label className="sg-add-admin-modal-password-label">
              Password
            </label>
            <Field className="form-control" type="password" name="password" value={editPassword} onChange={(e) => this.setState({editPassword: e.target.value})}/>
            <ErrorMessage name="password" component="div" />
            <div style={{display: "flex"}}>
              <button type="submit" className="sg-add-admin-modal-submit-button">
                Submit
              </button>
              <button className="sg-add-admin-modal-cancel-button" style={{marginLeft: "10px"}} onClick={this.toggleEditModal}>
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    ); 
  }

  toggleModal() {
    const {modalOpen} = this.state
    this.setState({
      modalOpen: !modalOpen,
      email: "",
      password: "",
    });
  }

  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    })
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    const {email, password} = this.state;
    fetchAPI("/admin/new_admin", (json) => {
      if (json.errors) {
        alert(json.errors[0]);
      } else {
        this.toggleModal();
        this.getAdmins();
        this.renderAdmins();
      }
    },
    {
      method: 'POST',
      body: {
        email: email,
        password: password,
      }
    })
  }

  handleEditSubmit(e) {
    e.preventDefault();
    const {selectedEditEmail, editPassword} = this.state;
    fetchAPI("/admin/edit_admin", (json) => {
      if (json.errors) {
        alert(json.errors[0]);
      } else {
        this.toggleEditModal();
      }
    },
    {
      method: 'POST',
      body: {
        email: selectedEditEmail,
        password: editPassword,
      }
    })
  }

  render() {
    return(
      <>
        <div className="sg-admin-custom-report-container">
          <div className="sg-add-admin">
            <button className="sg-add-admin-button" onClick={this.toggleModal.bind(this)}>+ Add an Admin</button>
            {this.renderAdmins()}
          </div>
        </div>
        <Modal 
          className="sg-add-admin-modal"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <div className="sg-add-admin-modal-inner">
            <div className="sg-add-admin-modal-close-button" onClick={this.toggleModal.bind(this)}> &#10005; </div>
            {this.renderModal()}
          </div>
        </Modal>
        <Modal 
          className="sg-add-admin-modal"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={this.state.editModalOpen}
          onRequestClose={this.toggleEditModal}
          contentLabel=""
        >
          <div className="sg-add-admin-modal-inner">
            <div className="sg-add-admin-modal-close-button" onClick={this.toggleEditModal}> &#10005; </div>
            {this.renderEditModal()}
          </div>
        </Modal>
        <Modal 
          className="sg-add-admin-modal"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={this.state.deleteModalOpen}
          onRequestClose={this.toggleDeleteModal}
          contentLabel=""
        >
          <div className="sg-add-admin-modal-inner">
            <div className="sg-add-admin-modal-close-button" onClick={this.toggleDeleteModal}> &#10005; </div>
            {this.renderDeleteModal()}
          </div>
        </Modal>
      </>
    )
  }
}

export default AddAdminSection;