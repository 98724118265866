import React from "react";
import PropTypes from "prop-types";
import DefaultTooltip from "@layout/tooltips/DefaultTooltip"
import { uuidv4 } from '@utility/FileUtils'
class ReportBubble extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      id: uuidv4()
    }
  }

  renderToolTips(){
    const { bubbleTooltip, downloadTooltip }= this.props;
    return (
      <>
        <DefaultTooltip
          tooltipId={this.getDownloadTooltipId()}
        >
          {downloadTooltip || "Click to download detailed report"}
        </DefaultTooltip>
        {
          bubbleTooltip? (
            <DefaultTooltip
              tooltipId={this.getBubbleTooltipId()}
            >
              {bubbleTooltip}
            </DefaultTooltip>
          ):""
        }
      </>
    )
  }

  uuidv4() {
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }



  getDownloadTooltipId() {
    return `sg-admin-download-tooltip-${this.state.id}`
  }

  getBubbleTooltipId() {
    return `sg-admin-bubble-tooltip-${this.state.id}`
  }
  render() {
    const { url, title, count, background, font_color, type} = this.props;
    return (
      <div className="sg-platform-report-bubble-container">
        {this.renderToolTips()}
        <div
          data-for={this.getBubbleTooltipId()}
          data-tip
          style={{backgroundColor: background||"#0D274C"}}
          className="sg-platform-report-bubble"
        >
          {
            type === "nom_bubble" ?
            <div className="sg-platform-report-bubble-content sg-platform-report-nom-bubble-content">
              <div className="sg-platform-report-bubble-count" style={{color: font_color||"#ffffff"}}>
                {count}
              </div>
              <div className="sg-platform-report-bubble-title" style={{color: font_color||"#ffffff"}}>
                {title}
              </div>
            </div>
            :
            <div className="sg-platform-report-bubble-content">
              <div className="sg-platform-report-bubble-title" style={{color: font_color||"#ffffff"}}>
                {title}
              </div>
              <div className="sg-platform-report-bubble-count" style={{color: font_color||"#ffffff"}}>
                {count}
              </div>
            </div>
          }
        </div>
        {url ? (
          <a
            data-for={this.getDownloadTooltipId()}
            data-tip
            href={url}
            target="_blank"
            style={{backgroundColor: background||"#0D274C"}}
            className="sg-platform-report-bubble-download">
            <img src="/images/admin/file-arrow-down.png"/>
          </a>
        ) : ""}
      </div>
    )
  }

}

export default ReportBubble;
