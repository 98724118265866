import React from "react";
import PropTypes from "prop-types";

class SwitcherStatus extends React.Component {
  constructor(props) {
    super(props);
    let dayNumber = localStorage.getItem('dayNumber');
    const attendeeValues = [
      {value: "0", label: "GTM (SumoYou)"},
      {value: "1", label: "non-GTM (SKO)"},
      {value: "2", label: "AM Group"},
      {value: "3", label: "PM Group"}
    ];
    let profileNumber = localStorage.getItem('profileNumber');

    this.state = {
      dayNumber: dayNumber,
      dayTime: localStorage.getItem('time'),
      attendeeType: attendeeValues[profileNumber] ? attendeeValues[profileNumber].label : null,
    };
  }

  render() {
    const { dayNumber, dayTime, attendeeType } = this.state;
    if (dayNumber !== null || dayTime !== null || attendeeType !== null) {
      return <div className="switcher-status">Switcher on</div>;
    }
    return "";
  }
}

export default SwitcherStatus;
