import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"

class AdminWhitelistDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      showDeactivated: false,
      whitelists: props.whitelists,
    }
  }

  renderUsers(){
    return this.getUsersFiltered().map(x=> this.renderUser(x));
  }
  renderUser(user) {
    const {attendees} = this.props;
    const attendee = user.attendee
    return (
      <tr className={`sg-admin-user-row ${user.deleted_at ? "sg-admin-user-row-deleted" : ""}`}>
        <td>
          {user.email}
        </td>
        <td>
          {user.created_at}
        </td>
        <td>
          {user.deleted_at}
        </td>
        <td>
          {user.deleted_at ?
            (<button onClick={() => this.addUserToWhitelist(user.email)}>Reactivate</button> ) : (<button onClick={() => this.deactivateUser(user.email)}>Deactivate</button> )
          }
        </td>
      </tr>
    )
  }

  getUsersFiltered() {
    let { whitelists, showDeactivated } = this.state;
    const { searchQuery } = this.state;

    whitelists = whitelists.filter(user => {
      if(user.email.toLowerCase().includes(searchQuery.toLowerCase())){
        return true;
      }
      return false
    });
    if(!showDeactivated) {
      whitelists = whitelists.filter(user =>
        user.deleted_at == null
      );
    }

    return whitelists
  }

  renderAddNewButton() {
    const { searchQuery } = this.state;
    if(searchQuery && searchQuery.length > 0) {
     return (
       <button onClick={() => this.addUserToWhitelist(searchQuery)}className="registration-next-button">
          Add {searchQuery} to whitelist
      </button>
     )
    }
  }

  addUserToWhitelist(email) {
    const { whitelists } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/admin/whitelist`, {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {
          email: email
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.whitelist) {
        this.setState({
          whitelists: whitelists.filter(x=>x.email !=json.whitelist.email).concat(json.whitelist),
          searchQuery: "",
        })
      }
    });
  }

  deactivateUser(email) {
    const { whitelists } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/admin/whitelist/1`, {
      method: 'DELETE',
      redirect: "manual",
      body: JSON.stringify(
        {
          email: email
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.whitelist) {
        this.setState({
          whitelists: whitelists.filter(x=>x.email !=json.whitelist.email).concat(json.whitelist),
          searchQuery: "",
        })
      }
    });
  }

  render() {
    const { searchQuery, showDeactivated } = this.state;
    return (
      <>
        <div className="row sg-platform-report-section">
          <div className="col-xs-6">
            Search or add new:
            <br />
            <input className="form-control" value={searchQuery} onChange={(e)=>this.setState({searchQuery: e.target.value})}/>
            <br />
            {this.renderAddNewButton()}
          </div>
          <div className="col-xs-2">
            Show Deactivated
            <br />
            <input type="checkbox" className="" checked={showDeactivated} onChange={(e)=>this.setState({showDeactivated: e.target.checked})}/>
          </div>
          <div className="col-xs-12">
            <table className="sg-admin-user-panel-table">
              <tr>
                <th style={{paddingLeft:"20px"}}>
                  Email
                </th>
                <th>
                  Created at
                </th>
                <th>
                  Deleted at
                </th>
                <th>
                  Options
                </th>
              </tr>
              {this.renderUsers()}
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default AdminWhitelistDashboard;
