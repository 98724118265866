// // React component mounting
// import { mountReactComponents } from "./react_mounter";

// // Import mounted (top-level) components here
// // import ExampleApp from "@example/ExampleApp";

// // Add components to the mountComponents list
// // key and value should both match the component name
// // const mountComponents = {
// //   ExampleApp: ExampleApp
// // };

// mountReactComponents(mountComponents);

// // Glob loader
// //
// // To avoid having to manually import top-level components, you can optionally
// // use the glob loader. To do this, comment out the above loader code,
// // and uncomment the code below.
// //
// // You will also need to install the eslint-plugin-glob-loader plugin:
// // https://github.com/thomaschaaf/esbuild-plugin-import-glob
// //
// // WARN: This plugin is unmaintained. Use it and the glob loader at your
// // own risk. It's working as of esbuild 0.17, but keep an eye on it after
// // major esbuild version updates.
// //
// // import * as components from "./components/**/*";
// // import { mountReactComponentsGlob } from "./react_mounter";
// // const { default: componentsArray, filenames } = components;
// // mountReactComponentsGlob(componentsArray.map(x=>x.default), filenames);



// Entry point for the build script in your package.json

// Hotwired
// import "@hotwired/turbo-rails";

// React components
import "mount"

import mount_component from 'mount';
import * as components from './components/**/*';
const { default: componentsArray, filenames } = components;
mount_component(componentsArray.map(x=>x.default), filenames);