import React from "react";
import PropTypes from "prop-types";

class AdminOpenablePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.defaultOpen,
    };
    this.toggleOpen= this.toggleOpen.bind(this);
  }

  toggleOpen() {
    const { open } = this.state;
    this.setState({
      open: !open
    })
  }

  renderHeaderDirectional() {
    const { open, button } = this.props;
    if(!button) {
      return (
        <div className="sg-admin-openable-panel-header-signifier-container">
          <img src={"/images/admin/openable-arrow.svg"} className={`sg-admin-openable-panel-header-signifier ${open? "open" : "closed"}`}/>
        </div>
      )
    }

  }

  renderHeaderButton() {
    const { button } = this.props;
    return (
      <div className="sg-admin-openable-panel-header-signifier-container">
        {button}
      </div>
    )
  }

  isOpen() {
    const { forceOpen } = this.props;
    const { open } = this.state;

    return forceOpen || open
  }

  render() {
    const { open } = this.state;
    const { children, header, outerStyle, color } = this.props;
    return (
     <div style={{...outerStyle}} className="sg-admin-openable-panel">
        <div style={{color: color||"#0D274D"}} onClick={this.toggleOpen} className={`sg-admin-openable-panel-header ${open ? "open" : "closed"}`}>
          {header}
          {this.renderHeaderButton()}
          {this.renderHeaderDirectional()}
        </div>
        {this.isOpen() ? (
          <div className="sg-admin-openable-panel-body">
            {children}
          </div>
        ):""}

     </div>
    )
  }

}

export default AdminOpenablePanel;
