import React, { Component } from 'react';
// import Select from 'react-select';
// import { getCountrySelectOptions } from "./CountryCodes";
export default class RegistrationCheckBoxField extends Component {

  getErrorMessages() {
    let errorMessages = {
      selectOption: "Please select an option to continue.",
      ensureAllFields: "Please make sure all fields have been properly filled in.",
      ...(this.props.errorMessages||{})
    }
    return errorMessages
  }

  renderCustomSize(question, defaultQuestionSize) {
    let currentSize = defaultQuestionSize;
    let currentClass = 'col-sm-12';
    if (question.use_custom_size && question.custom_size !== 0) {
      currentSize = question.custom_size;
    }
    switch(currentSize) {
      case 1:
        currentClass = 'col-sm-12';
        break;
      case 2:
        currentClass = 'col-sm-6';
        break;
      case 3:
        currentClass = 'col-sm-4';
        break;
      case 4:
        currentClass = 'col-sm-3';
        break;
      case 6:
        currentClass = 'col-sm-2';
        break;
      default:
        currentClass = currentClass;
    }
    return currentClass;
  }

  translateCSS(css) {
    // TODO: Implement css -> jsx styling
    let jsonCSS = {}
    if (css !== '') {
      try {
        jsonCSS = JSON.parse(css)
      } catch (e) {
        return {}
      }
    }
    return jsonCSS;
  }

  getFormikValue(attribute_id, formikProps) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    if (this.isMetaDataField(attribute_id)) {
      // Metadata Field
      let formikPropsParticipant = formikProps.values["participant"];
      return formikPropsParticipant["metadata"][splitQuestionId[2]];
    } else {
      // Participant Field
      let formikPropsParticipant = formikProps.values["participant"];
      return formikPropsParticipant[splitQuestionId[1]];
    }
  }

  splitObjectField(attribute_id) {
    return attribute_id.split('.');
  }

  isMetaDataField(attribute_id) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    return splitQuestionId.length > 2
  }

  setFormikValue(value, attribute_id, formikProps) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    if (this.isMetaDataField(attribute_id)) {
      // Metadata Field
      let formikPropsParticipant = formikProps.values["participant"];
      formikPropsParticipant["metadata"][splitQuestionId[2]] = value;
      formikProps.setFieldValue('participant', formikPropsParticipant);
    } else {
      // Participant Field
      let formikPropsParticipant = formikProps.values["participant"];
      formikPropsParticipant[splitQuestionId[1]] = value;
      formikProps.setFieldValue('participant', formikPropsParticipant);
    }
  }

  renderQuestionLabel(question) {
    return (
      <div className="label-wrapper">
        <label className={`label-english ${question.required ? 'required':''}`}> {question.label} </label>
      </div>
    );
  }

  render() {
    const { type, formikProps, question, erroring } = this.props;

    let value = this.getFormikValue(question.attribute_id, formikProps, type);
    if (typeof value === 'string') {
      value = value.split(',');
    }

    let checkboxOptions = question.options.map(option =>
      <div
        className={`col-xs-12 col-md-3 ${question.custom_class}`}
        style={this.translateCSS(question.custom_css)}
        key={`checkbox-question-${question.gid}-option-${option.value}`}
      >
        <div className="radio-option-wrapper">
          <img
            src={ value !== undefined && value.includes(option.value) ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
            style={{cursor:"pointer", marginBottom: "7px"}}
            onClick={(e) => {
              if (value) {
                let index = value.indexOf(option.value);
                if (index !== -1) {
                  value.splice(index, 1);
                } else {
                  value.push(option.value);
                }
                this.setFormikValue(value.filter(n => n).join(','), question.attribute_id, formikProps, type);
              } else {
                this.setFormikValue([option.value], question.attribute_id, formikProps, type);
              }
            }}
          />
          <div className="radio-option-label">{option.label}</div>
        </div>
      </div>
    )
  
    return(
      <>
        <div className={`col-xs-12 ${this.renderCustomSize(question, 1)} ${question.custom_class}`} style={this.translateCSS(question.custom_css)}>
          {this.renderQuestionLabel(question)}
        </div>
        {checkboxOptions}
        <div className={`col-xs-12`} />
        {
          erroring ?
          <div className={`col-xs-12 ${this.renderCustomSize(question, 1)}`}>
            <div className="registration-field-error">
              <>{this.getErrorMessages()['selectOption']}</>
            </div>
          </div>
          :
          <></>
        }
      </>
    )
  }
}
