import React from "react";
import PropTypes from "prop-types";

class TimeSwitcherClock extends React.Component {
  constructor(props) {
    super(props);
    let dayNumber = localStorage.getItem('dayNumber');
    let time = localStorage.getItem('time');
    this.state = {
      showClock: (dayNumber !== null || props.clockIsOn) ? true : false,
      timeOverride: time === null ? "" : time
    };
    this.setTime = this.setTime.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { clockIsOn } = nextProps;
    if (clockIsOn) {
      this.setState({ showClock: true });
    }
  }

  setTime(e) {
    localStorage.setItem("time", e.target.value);
    this.setState({
      timeOverride: e.target.value
    });
  }

  render() {
    const { showClock, timeOverride } = this.state;
    if (showClock) {
      return (
        <div className="sg-header-title-container">
          <input
            type="time"
            style={{marginTop: "5px", marginBottom: "5px"}}
            onChange={this.setTime} value={timeOverride}
            onKeyPress={event => {if (event.key === 'Enter') {location.reload();}}}
          />
        </div>
      );
    }
    return "";
  }
}

export default TimeSwitcherClock;
