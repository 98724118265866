export function getTotalNoms(nominations) {
  return nominations.length;
}

export function getTotalNomsByNomStatus(nominations, status) {
  return nominations.filter(nom => nom.status === status).length;
}

export function getTotalNomsByInviteStatus(nominations, invite_status) {
  return nominations.filter(nom => nom.invite_status === invite_status).length;
}

export function getByContactCompanySubSegment(nominations, sub_segment) {
  return nominations.filter(nom => nom.contact.company.sub_segment === sub_segment).length;
}

export function getByContactCompanyRegion(nominations, region) {
  return nominations.filter(nom => nom.contact.company.region === region).length;
}

export function getByContactCompanySalesCoverage(nominations, sales_coverage, value) {
  return nominations.filter(nom => nom.contact.company.sales_coverage === sales_coverage).length;
}
