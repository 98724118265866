import React from "react";
import PropTypes from "prop-types";
class EmailSectionStats extends React.Component {

  render() {
    const { email } = this.props;
    const { activity } = email;
    const todo = "true"
    const delivered = activity.map(x=>x.activity).flat().filter(x=>x.activity_type=="delivered").length

    const recipients = activity.map(x=>x.emails).flat()
    console.log(recipients);
    const main_recipients = recipients.map(x=>x.email);
    // const cc_emails = recipients.map(x=>x.cc.split(",")).flat();

    const activities = activity.map(x=>x.activity).flat()
    const clicks = activities.filter(x=>x.activity_type=="clicked")
    const opens = activities.filter(x=>x.activity_type=="opened")
    const bounces = activities.filter(x=>x.activity_type=="bounced")
    return (
      <div className="sg-admin-mail-counter-container">
        <div className="sg-admin-mail-counter">
          Opened {opens.filter(x=>main_recipients.indexOf(x.recipient)!=-1).length} <span> Main Recipients</span> <span>({opens.length} Total)</span>
        </div>
        <div className="sg-admin-mail-counter">
          Clicks {clicks.filter(x=>main_recipients.indexOf(x.recipient)!=-1).length} <span> Main Recipients</span> <span>({clicks.length} Total)</span>
        </div>
        <div className="sg-admin-mail-counter">
          Bounces {bounces.filter(x=>main_recipients.indexOf(x.recipient)!=-1).length} <span>Main recipients ({bounces.length} Total)</span>
          <a target="_blank" href={`/admin/reports/postmark_bounce_report?title=${todo}`} style={{marginLeft:""}}>
            <img src="/images/admin/email-bounce-download.svg" />
          </a>
        </div>
        <div className="">
          <br/>
          * Main recipients are reported back by their email provider, who may provide incorrect information, especially in the case of bounces.
        </div>
      </div>
    )
  }
}

export default EmailSectionStats;
