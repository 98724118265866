import React, { Component } from 'react';
import Select from 'react-select';
import { getCountrySelectOptions } from "./CountryCodes";
export default class RegistrationDropdownField extends Component {

  translateCSS(css) {
    // TODO: Implement css -> jsx styling
    let jsonCSS = {}
    if (css !== '') {
      try {
        jsonCSS = JSON.parse(css)
      } catch (e) {
        return {}
      }
    }
    return jsonCSS;
  }

  getFormikValue(attribute_id, formikProps) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    if (this.isMetaDataField(attribute_id)) {
      // Metadata Field
      let formikPropsParticipant = formikProps.values["participant"];
      return formikPropsParticipant["metadata"][splitQuestionId[2]];
    } else {
      // Participant Field
      let formikPropsParticipant = formikProps.values["participant"];
      return formikPropsParticipant[splitQuestionId[1]];
    }
  }

  splitObjectField(attribute_id) {
    return attribute_id.split('.');
  }

  isMetaDataField(attribute_id) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    return splitQuestionId.length > 2
  }

  setFormikValue(value, attribute_id, formikProps) {
    const splitQuestionId = this.splitObjectField(attribute_id);
    if (this.isMetaDataField(attribute_id)) {
      // Metadata Field
      let formikPropsParticipant = formikProps.values["participant"];
      formikPropsParticipant["metadata"][splitQuestionId[2]] = value;
      formikProps.setFieldValue('participant', formikPropsParticipant);
    } else {
      // Participant Field
      let formikPropsParticipant = formikProps.values["participant"];
      formikPropsParticipant[splitQuestionId[1]] = value;
      formikProps.setFieldValue('participant', formikPropsParticipant);
    }
  }

  renderQuestionLabel(question) {
    return (
      <div className="label-wrapper">
        <label className={`label-english ${question.required ? 'required':''}`}> {question.label} </label>
      </div>
    );
  }

  render() {
    const { type, formikProps, question, erroring } = this.props;
    let value = this.getFormikValue(question.attribute_id, formikProps, type);
    if (question.attribute_id === 'participant.override_work_address_country') {
      return (
        <div className={`col-xs-12 col-sm-6 ${question.custom_class}`} style={this.translateCSS(question.custom_css)}>
          {this.renderQuestionLabel(question)}
          <Select
            options={getCountrySelectOptions()}
            classNamePrefix='registration-select'
            value={getCountrySelectOptions().find(option => value === option.value)}
            onChange={selected => {
              this.setFormikValue(selected.value, question.attribute_id, formikProps, type);
            }}
            isDisabled={question.disabled}
            className={erroring ? "registration-select-erroring" : ""}
          />
        </div>
      );
    }

    return (
      <div className={`col-xs-12 col-sm-6 ${question.custom_class}`} style={this.translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <Select
          options={question.options}
          classNamePrefix='registration-select'
          value={question.options.find(option => value === option.value)}
          onChange={selected => {
            this.setFormikValue(selected.value, question.attribute_id, formikProps, type);
          }}
          isDisabled={question.disabled}
          className={erroring ? "registration-select-erroring" : ""}
        />
      </div>
    )
  }
}
