import React from "react";
import PropTypes from "prop-types";

class KbygFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="sg-kbyg-footer-container">
        <div className="sg-kbyg-footer-title">
          Social Media
        </div>
        <div className="sg-kbyg-footer-links">
          <span className="sg-kbyg-footer-hashtag">
            #CiscoLive
          </span>
          <a href="https://twitter.com/CiscoLive?/" target="_blank">
            <img src="/images/kbyg/twitter.svg" />
          </a>
          <a href="https://www.facebook.com/ciscoliveGlobal/" target="_blank">
            <img src="/images/kbyg/facebook.svg" />
          </a>
          <a href="https://www.instagram.com/ciscolive/" target="_blank">
            <img src="/images/kbyg/instagram.svg" />
          </a>
        </div>
      </div>
    )
  }
}

export default KbygFooter;
