import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

class DeclineTrackingComponent extends React.Component {
  constructor(props) {
    super(props);
    
    this.sendTrackingData = this.sendTrackingData.bind(this);
  }

  componentDidMount() {
    setTimeout(this.sendTrackingData , 1000);

  }

  sendTrackingData() {
    const { data_hash } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(data_hash) {
      fetch('/track/decline_registration', {
        method: 'POST',
        redirect: "manual",
        body: JSON.stringify(
          {
            data_hash: data_hash,
          }
        ),
        headers: {
          "Content-Type": 'application/json',
          "X-CSRF-Token": token,
        }
      });
    }
  }

  render() {
    return (
      <>

      </>
    );
  }
}

export default DeclineTrackingComponent;
