import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import DropzoneComponent from "./DropzoneComponent"

class QuestionPicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      projectImage: null,
      previewImage: props.previewImage,
      changed: false,
    }
    this.setFiles = this.setFiles.bind(this);
  }

  setFiles(name, file, preview, tagList) {
    if(file == null) {
      this.removeImage();
    }
    if(this.props.onDirty) {
      this.props.onDirty(file, preview, preview, tagList);
    }
    this.setState({
      projectImage: file,
      previewImage: preview
    });
  }


  removeImage() {
    if(this.props.dontFetch) {
      return;
    }
    var form_data = new FormData();
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch('/registration/remove_question_picture', {
      method: 'POST',
      redirect: "manual",
      body: {},
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {

      } else {
        console.log(json.error);
      }
    });
  }

  render() {
    const { user } = this.props;
    const { changed, previewImage}  = this.state;
    return (
      <div className="reg-profile-icon square">
        <div className={changed ? 'sg-profile-picture-changed' : ''}></div>
        <div className="reg-profile-overlay">
          <div className="icon-div">
            {!(previewImage) ? (<img className="reg-profile-image" src="/images/reg-profile-question.png" />):""}
            <DropzoneComponent freeRatio={true} showOptions={true} name="drawing" setFiles={this.setFiles} showPreviews={true} editCrop={true} setImage={previewImage} />
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionPicture;
