import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationEmailValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      error: null,
      emailValue: "",
    }
    this.verifyEmail = this.verifyEmail.bind(this);
  }


  verifyEmail(e) {
    e.preventDefault()
    const { features } = this.props;
    const { emailValue, selectedDate} = this.state;
    if(features &&  features.pick_a_date) {
      if(selectedDate == null) {
        this.setState({
          error: "Please select a program to register for."
        })
        return;
      }
    }
    fetchAPI(`/registration/verify_email`,(json)=>{
      let error = null;
      let attendee = null
      if(json){
        attendee = json.attendee;
        error = json.error;
      }else {

      }

      if( error ) {
        console.log({error})
        this.setState({
          error: error
        })
      } else if (!error && attendee) {
        window.location = `/registration?id=${attendee}${features.pick_a_date ? "&ticket="+selectedDate.value:""}${features.sandbox ? "&sandbox=true":""}`
      }else {

      }
    } ,{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }

  renderPickADate() {
    const { features } = this.props;
    const { selectedDate } = this.state;
    console.log({features})
    console.log(features.pick_a_date);
    if(features && features.pick_a_date) {
      return (
        <>
          <label>Pick a program date*</label>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
        </>
      )
    }
  }

  render() {
    console.log({
      x: this.props.features
    })
    const { emailValue, error } = this.state;
    return (
      <form onSubmit={this.verifyEmail} className="att-proam-registration-email-validation registration-form ">
        <div className="att-proam-registration-email-validation-copy">
          <b>We look forward to welcoming you at the AT&T Pebble Beach Pro-Am.</b>
          <br/>
          <br/>
          The Cisco experience at the AT&T Pebble Beach Pro-Am is by invitation-only.
          <br/>
          <br/>
          Please ensure the email address provided is that of the registrant.
        </div>
        <div className="att-proam-registration-email-validation-fields">
          <br/>
          <label>Enter Email Address*</label>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
          <br/>
          {this.renderPickADate()}
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error">
            {error}
          </div>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <button
            type="button"
            onClick={this.verifyEmail}
            className="sg-round-button sg-primary-button"
          >
            Continue
          </button>
        </div>
      </form>
    );
  }
}

export default RegistrationEmailValidation;
