import React from "react";
import PropTypes from "prop-types";

class FlipFlopFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  convertToReadable(str) {
    return str
      .split("_")
      .filter(x => x.length > 0)
      .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
      .join(" ");
  }

  render() {
    const { flipflops }= this.props;
    const renderedFlipFlops = flipflops.map(flipflop =>
      <div className={`flipflop-label`}>
        {this.convertToReadable(flipflop.key)}: <span className={flipflop.enabled ? 'flipflop-true' : 'flipflop-false'}>{this.convertToReadable(flipflop.enabled === undefined ? 'false' : flipflop.enabled.toString())}</span>
      </div>
    );
    return (
      <>{renderedFlipFlops}</>
    );
  }

}

export default FlipFlopFeatures;
