import React, { Component } from 'react';
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';

export default class RegistrationTimeField extends Component {

  translateCSS(css) {
    // TODO: Implement css -> jsx styling
    let jsonCSS = {}
    if (css !== '') {
      try {
        jsonCSS = JSON.parse(css)
      } catch (e) {
        return {}
      }
    }
    return jsonCSS;
  }

  getFormikValue(attribute_id, formikProps) {
    const splitQuestionId = attribute_id.split('.');
    let formikPropsParticipant = formikProps.values["participant"];
    return formikPropsParticipant["metadata"][splitQuestionId[2]];
  }

  setFormikValue(value, attribute_id, formikProps) {
    const splitQuestionId = attribute_id.split('.');
    let formikPropsParticipant = formikProps.values["participant"];
    formikPropsParticipant["metadata"][splitQuestionId[2]] = value;
    formikProps.setFieldValue('participant', formikPropsParticipant);
  }

  generateTimeAsText(e) {
    if (!isNaN(e.$H) && !isNaN(e.$m) && e.$d) {
      return e.$d.toTimeString().slice(0, 5)
    } else {
      return null;
    }
  }

  renderQuestionLabel(question) {
    return (
      <div className="label-wrapper">
        <label style={{fontSize: "16px", marginBottom: "5px", marginTop: "24px", lineHeight: "20px"}} className={`label-english ${question.required ? 'required':''}`}> {question.label} </label>
      </div>
    );
  }

  render() {
    const { type, formikProps, question, erroring } = this.props;
    let value = this.getFormikValue(question.attribute_id, formikProps, type);
    return(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div 
          className={"time-field-container col-xs-12 col-sm-6 time-field-pac"} 
          style={this.translateCSS(question.custom_css)}
        >
          <div
            className={`time-field-label`}
          >
            {this.renderQuestionLabel(question)}
          </div>
          <div className={"time-field"}>
            <TimeField 
              defaultValue={value ? dayjs(new Date(null, null, null, value.slice(0, 2), value.slice(3, 5))) : null}
              fullWidth={true}
              onChange={(e) => {
                this.setFormikValue(this.generateTimeAsText(e), question.attribute_id, formikProps, type)
              }}
            />
          </div>
          {
            erroring ?
            <div className="col-xs-12">
              <div className="registration-field-error">
                <>Please enter a time to continue.</>
              </div>
            </div>
            :
            <></>
          }
        </div>
      </LocalizationProvider>
    )
  }
}
