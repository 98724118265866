import React from "react";
import PropTypes from "prop-types";

class NominationSnapshotTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getTotalNominated() {
    const { nom_tool_nominations, data } = this.props;
    let total = 0;
    data.rows.forEach(row => {
      total = total + row.value;
    });
    return total;
  }

  renderRows() {
    const { nom_tool_nominations, data } = this.props;
    const renderedRows = data.rows.map(row =>
      <tr>
        <td>
          {row.label}
        </td>
        <td>
          {row.value}
        </td>
      </tr>
    );
    return renderedRows;
  }

  render() {
    const { nom_tool_nominations, data } = this.props;
    return (
      <div className="col-xs-12 col-md-6 snapshot-table-wrapper">
        <div className="snapshot-table-title"><b>{data.title}</b> <i>{`(${this.getTotalNominated()} nominated)`}</i></div>
        <table className="snapshot-table">
          <tr>
            <th>Area</th>
            <th># of Nominated Customers</th>
          </tr>
          {this.renderRows()}
        </table>
      </div>
    );
  }
}

export default NominationSnapshotTable;
