import React from "react";

class UserIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.icon_colors = [
      "#21B9CC", "#0092D2", "#007E33", "#404A99", "#279F00", "#A0CC00", "#006792", "#6D2A5F", "#A34891"
    ]
  }

  renderInnerIcon() {
    const { user, fontSize } = this.props;
    const { profile_image, name_first, name_last, id} = user;
    if(profile_image){
      return (
        <div
          className="sg-user-icon-inner"
          style={{backgroundImage: `url(${profile_image})`, backgroundColor: "white"}}
        />
      )
    }
    else {
      return (
        <div
          className="sg-user-icon-inner"
          style={{backgroundColor: this.icon_colors[id % this.icon_colors.length], fontSize: this.getFontSize()}}
        >
          {name_first ? name_first[0] : ""}
          {name_last ? name_last[0] : ""}
        </div>
      )
    }
  }

  getFontSize() {
    const { fontSize, size } = this.props;
    if(fontSize) {
      return fontSize;
    }
    if(size){
      switch(size) {
        case "small":
          return "22px";
        case "medium":
          return "30px";
        case "large":
          return "50px";
      }
    }
    return "16px";
  }

  render() {
    return (
      <div className="sg-user-icon">
        {this.renderInnerIcon()}
      </div>
    );
  }
}

export default UserIcon;
