import React from "react";
import PropTypes from "prop-types";


class HeaderMusicPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: localStorage.getItem('playingMusic') == "true",
    };
    this.viewer = null;
    this.unmuteAndPlay = this.unmuteAndPlay.bind(this);
  }


  render() {
    const { playing } = this.state;

    return (
      <div id="sg-header-music-id" className="sg-header-music-player">
      </div>
    );
  }
}

export default HeaderMusicPlayer;
