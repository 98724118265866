import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select'
import {activities, preferredRoomType, travelInformation, getApparelSizeOptions,specialAccomodations, dietaryRestrictions, getCountrySelectOptions, getStateSelectOptions, getTimezoneOptions, getTimezoneInfo, getJobTypes}  from "../registration/RegistrationOptions"
import RegistrationInvitationModal from "../registration/RegistrationInvitationModal"
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
class AmRegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageOneError: null,
      pageThreeError: null,
      pageTwoError: null,
      checkError: false,
      emailTaken: false,
      dietaryRestrictions: [],
      additionalDates: [],
      saving: false,
      selectedActivities: [],
      features: props.features,

    }
    this.submitRegistration = this.submitRegistration.bind(this);
  }

  renderTester() {
    if(false) {
      const pages = [
        {id: 1},
        {id: 4},
      ];

      return (
        <div className="sg-reg-page-swapper">
          {pages.map(x=>(
            <div onClick={()=>this.setState({
              page: x.id
            })}className="sg-reg-page-swapper-item">
              Go To Page {x.id}
            </div>
          ))}
        </div>
      )
    }
  }

  pageHiddenClass(renderedPage) {
    const { page } = this.state;
    if(renderedPage == page) {
      return "visible";
    }
    return "hidden";
  }

  isEmailPersonal(email) {
    return false;
  }

  includesPolyfill(string1, string2) {
    return string1.indexOf(string2) != -1
  }

  isOvernight(formikProps){
    return false;
  }

  isPageTwoValid(formikProps) {
    return true; //TODO
  }

  isPageOneValid(formikProps) {
    //TODO
    const { fields } = this.props;
    const { values } = formikProps;
    let fieldList = [
      'customer_first_name',
      'customer_name_last',
      'customer_company',
      'customer_email',
      'contact_name_first',
      'contact_name_last',
      'contact_email',
      'contact_work_phone',
      'ecfirstname',
      'eclastname',
      'ecnumber',
      'ecrelation',
      'photographagreement',
      'privacyagreement'
    ];

    if(values.user.privacyagreement!='True' )  {
      return false;
    }
    if(values.user.photographagreement!='True' )  {
      return false;
    }
    const daySlug = values.user.programname
    if(['day1','day2','day3','day4','day5'].indexOf(daySlug) == -1) {
      return false;
    }

    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    return true;
  }

  renderPickADate(formikProps) {
    const { fields, ticket_info } = this.props;
    const dates = [
      {slug: "day1", topLine: "Wednesday", bottomLine: "February 1, 2023", subbottom: "Cisco Million Dollar Hole-in-One for Charity"},
      {slug: "day2", topLine: "Thursday", bottomLine: "February 2, 2023", subbottom: "First Round"},
      {slug: "day3", topLine: "Friday", bottomLine: "February 3, 2023", subbottom: "Second Round"},
      {slug:"day4",topLine: "Saturday", bottomLine: "February 4, 2023", subbottom: "Third Round"},
      {slug:"day5",topLine: "Sunday", bottomLine: "February 5, 2023", subbottom: "Final Round"}
    ].map(x=>{
      x.ticket_object = ticket_info.find(y=>x.slug == y.slug)
      return x
    })
    console.log(dates);

    const { checkError } = this.state;
    const { values } = formikProps;
    const daySlug = values.user.programname;
    if(!this.isOvernight(formikProps)) {
      const rendereredDates = dates.map(x=> (
        <div onClick={()=> {
          if(x.ticket_object.available) {
            const { user } = formikProps.values;
            user['programname'] = x.ticket_object.slug;
            formikProps.setFieldValue('user', user);
          }
        }} disabled={!x.ticket_object.available} className={`sg-masters-date-select-item ${daySlug == x.slug ? "active" : ""} ${!x.ticket_object.available ? "disabled" : ""} `}>
          <div className="sg-masters-date-select-item-inner">
            <img src={`/registration/days/${x.slug}${daySlug == x.slug ? "" : "-active"}.png`} className="sg-masters-date-select-item-image"/>
            <div className="sg-masters-date-select-item-copy">
              <b>{x.topLine} {x.bottomLine}</b>
              <br/>
              {x.subbottom}
            </div>
            <img src={daySlug == x.slug ? `/registration/days/checkmark.png` : `/registration/days/checkmark-gray.png`} className="sg-masters-date-select-checkmark-image"/>
            <div className="sg-masters-date-select-item-disabled-message">
              No longer available
            </div>
          </div>
        </div>
      ))
      return (
        <>
        <div className="col-xs-12 sg-registration-h3-copy">
          Tournament Date
        </div>
        <div className="col-xs-12 sg-registration-large-copy">
          Select the tournament date you plan to attend with your registered customer/partner.
        </div>
        <div className="col-xs-12">
          <div className="sg-masters-date-select-item-container">
            {rendereredDates}
          </div>
          <div className="registration-field-error">
            {checkError && !this.isPageOneValid(formikProps) && ['day1','day2','day3','day4','day5'].indexOf(daySlug) == -1 ? "Please select an option to continue." : ""}
          </div>
        </div>
        <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
        </div>
        </>
      )
    }
  }

  renderPageOne(formikProps) {
    const { fields } = this.props;
    const { values } = formikProps;
    const { pageOneError, emailTaken, checkError, pageThreeError, fullPageError } = this.state;
    return (
      <>
      <div className={`registration-page-one container ${this.pageHiddenClass(1)}`}>
        <div className="row">
          <div className="col-xs-12 sg-registration-h3-copy">
            Registered Customer/Partner
          </div>
          <div className="col-xs-12 sg-registration-large-copy">
            Please enter the requested information below on your registered customer/partner.
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Customer/Partner First Name </label>
            {this.renderField('customer_first_name',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Customer/Partner Last Name </label>
            {this.renderField('customer_name_last',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Customer/Partner Company </label>
            {this.renderField('customer_company',formikProps,false)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Customer/Partner Email </label>
            {this.renderField('customer_email',formikProps)}
          </div>
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          {this.renderPickADate(formikProps)}
          <div className="col-xs-12 sg-registration-h3-copy">
            Your Contact Information
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> First Name </label>
            {this.renderField('contact_name_first',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Last Name </label>
            {this.renderField('contact_name_last',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Email </label>
            {this.renderField('contact_email',formikProps,false)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Mobile Telephone </label>
            {this.renderField('contact_work_phone',formikProps)}
          </div>
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-registration-h3-copy">
            Emergency Contact Name
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency Contact First Name </label>
            {this.renderField('ecfirstname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency Contact Last Name </label>
            {this.renderField('eclastname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency Contact Phone Number </label>
            {this.renderField('ecnumber',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Relation to Emergency Contact </label>
            {this.renderField('ecrelation',formikProps)}
          </div>
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-registration-h3-copy">
            Additional Guest Information
          </div>
          <div className="col-xs-12 sg-registration-large-copy">
            Do you have any dietary preferences?
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""  style={{marginTop:"5px"}}> <br/> </label>
            {this.renderSelectField('dietarypreference',formikProps, dietaryRestrictions(fields),false,  {placeholder: "None", not_required: true})}
          </div>
          {values.user.dietarypreference == "Food Allergies" || values.user.dietarypreference == "Other" ? (
            <div className="col-xs-12 col-sm-6">
              <label className=""  style={{marginTop:"5px"}}> Please specify</label>
              {this.renderField('dietarypreferenceother',formikProps, false, {not_required: true})}
            </div>
          ): ""}
          <div className="col-xs-12 sg-registration-large-copy">
            <br/>
            Do you have any physical needs or medical restrictions we can accommodate to make your attendance more comfortable for you?
          </div>
          <div className="col-xs-6">
            <label className="" style={{marginTop:"5px"}}> <br/> </label>
            {this.renderSelectField('specialaccomodations',formikProps, specialAccomodations(fields), false, {placeholder: "None", not_required: true})}
          </div>
          {values.user.specialaccomodations == "Other" ? (
            <div className="col-xs-12 col-sm-6">
              <label className=""  style={{marginTop:"5px"}}> Please specify</label>
              {this.renderField('specialaccomodationsother',formikProps, false, {not_required: true})}
            </div>
          ): ""}
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-registration-h3-copy">
            Photography Release
          </div>
          <div className="col-xs-12 att-proam-registration-section-copy">
            I understand that Cisco or its authorized representatives may take photographs and/or video recordings at the event and I grant permission to capture and record my participation. I further agree that any or all of the material photographed or recorded may be used, in any form, for trade, advertising or promotional purposes, by Cisco, its agents, and representatives. I release Cisco, its officers, and employees, and any and all persons involved from any liability in connection with the taking, recording, and use of images, photographs, and video/sound recordings. I waive all rights to any claims for payment or royalties in connection with the use of these materials. I also waive any right to inspect or approve any photo, video, or audio recording taken by Cisco or the person or its authorized representatives.
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('photographagreement',formikProps)}
            <label className="checkbox-label required" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>I confirm.</label>
            <div className="registration-field-error">
              {checkError && !this.isPageOneValid(formikProps) && values.user.photographagreement != "True" ? "Please click to confirm." : ""}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-registration-h3-copy">
            Collection of Personal Information
          </div>
          <div className="col-xs-12 sg-registration-copy">
            In order to provide you tickets for the 2023 AT&T Pebble Beach Pro-Am, your registration details will be shared with Cisco and its marketing agency on behalf of Cisco. Any personal information collected is subject to the respective privacy policies of each company. For more information on how Cisco collects and uses personal information and how you may access and correct such information or initiate a complaint, please see Cisco’s Privacy Statement.
            <br/>
            <br/>
            By clicking this box, I understand and acknowledge that Cisco is collecting personal information that I elect to provide about me for the purpose of associating that information with my registration for the 2023 AT&T Pebble Beach Pro-Am. Cisco will retain and use that personal information to provide me with information about my tickets.
            <br/>
            <br/>
            To complete your registration you must agree to the terms stated above.
            <br/>
            <br/>
          </div>
          <div className="col-xs-12">
            {this.renderCheckbox('privacyagreement',formikProps)}
            <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>I agree.</label>
            <div className="registration-field-error">
              {checkError && !this.isPageOneValid(formikProps) && values.user.privacyagreement != "True" ? "Please click to agree." : ""}
            </div>
          </div>

          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageOneValid(formikProps) ? pageOneError : ""}
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

  renderAdditionalDatesCheckbox(name, renderOptions={}) {
    let value = false;
    let { additionalDates } = this.state;
    try {
      value = additionalDates.indexOf(name) != -1
    }catch(e){

    }
    return(
      <img
        src={ value  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "2px"}}
        onClick={(e) => {
          if(value) {
            additionalDates = additionalDates.filter(x=> x != name)
          }else {
            additionalDates = additionalDates.concat(name)
          }
          this.setState({
            additionalDates
          })
        }}
      />
    )
  }

  renderTicketDates(formikProps) {

  }

  customOneWordValidation(answer) {
    return answer && answer.indexOf(' ') > -1;
  }


  renderField(name,formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && ((formikProps.values.user[name]||"").length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field disabled={properties['disabled']} onKeyUp={() => properties['onKeyUp'](formikProps)} component={properties['component']} className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`user[${name}]`}/>
    )
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }

    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.value
          formikProps.setFieldValue('user', user)
        }}
        filterOption={this.customFilter}
      />
    )
  }
  renderActivityCheckbox(id,label, formikProps) {
    let { selectedActivities } = this.state;
    let value = false;
    const renderOptions = {}
    try {
      value = selectedActivities == id;
    }catch(e){

    }
    return(
      <img
        src={ value  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          const selectedActivities = id
          this.setState({
            selectedActivities
          })

          user.activities = id
          formikProps.setFieldValue('user', user)
        }}
      />
    )
  }
  renderCheckbox(name, formikProps, customValidation=false, renderOptions={}) {
    let value = false;
    try {
      value = formikProps.values['user'][name] == "True";
    }catch(e){

    }
    if(renderOptions['forceValue'] != undefined ) {
      value = formikProps.values['user'][name] == renderOptions['forceValue']
    }
    return(
      <img
        src={ value  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(renderOptions['forceValue'] != undefined) {
            user[name] = renderOptions['forceValue']
          }else {
            user[name] = value ? "False" : "True"
          }

          formikProps.setFieldValue('user', user)
        }}
      />
    )
  }

  customFilter(option, searchText) {
    const ones =  option.label.toLowerCase().split(" ").filter(x=> x.startsWith(searchText.toLowerCase()));
    return ones.length > 0;
  }

  submitFormButtonOnClick(formikProps) {
    const { values } = formikProps;
    let fieldListPart1 = [
      "name_first",
      "name_last",
      "email",
      "job_title",
      "company",
    ];

    if(values.user['send_gift'] == "True"){
      fieldListPart1 = fieldListPart1.concat([
        'address_street_1',
        'address_city',
        'address_state',
        'address_postcode',
        'address_country'
      ])
    }
    const validFieldListPart1 = fieldListPart1.filter(field => values["user"][field] && values["user"][field].length >= 1);

    if(validFieldListPart1.length != fieldListPart1.length) {
      this.setState({
        pageThreeError: "Please make sure all fields are filled out correctly",
        checkError: true
      })
    }
    else if (values.user.confirmation != "True") {
      this.setState({
        pageThreeError: "Please agree to the terms and conditions",
        checkError: true
      })
    }
    else {
       this.setState({
         pageThreeError: null,
         checkError: true,
         fullPageError: null,
       })
       formikProps.handleSubmit()
     }
  }

  renderActivities(formikProps) {
    const { fields } = this.props;
    const allActivities = activities(fields)
    return allActivities.map(x=> (
      <div className="col-xs-12 col-md-4">
      {this.renderActivityCheckbox(x.value,x.label,formikProps)}
      <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>{x.label}</label>
      </div>
    ))
  }




  renderPageFour(formikProps) {
    return (
      <div className={`registration-page-one container ${this.pageHiddenClass(4)}`}>
        <div className="row">
          <div className="col-xs-12 sg-registration-copy">
            <div className="sg-registration-h1-copy">
              Thank you for submitting your request for tickets to the 2023 AT&T Pebble Beach Pro-Am.
            </div>
            <br/>
            Once we review your request, a confirmation email will be sent to {formikProps.values.user['contact_email']}.

            <br/>
            <br/>
            If you have any questions, please contact the Cisco Executive Relations Team.

            <br/>
            <br/>
            <div className="text-center">
              <br/>
              <a target="_blank" href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20Acct%20Manager%20Ticket%20Request%20%7C%202023%20AT%26T%20Pebble%20Beach%20Pro-Am">
                <button type="button" style={{paddingTop: "10px", marginBottom: "30px"}} className="sg-round-button sg-primary-button">
                  Contact Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )

  }

  renderHeader(formikProps) {
    const { page } = this.state;
    if(page == 1) {
      return (
        <div className="att-proam-registration-header">
          <div className="container sg-registration-h1-copy">
            Thank you for nominating your customer/partner for the 2023 AT&T Pebble Beach Pro-Am.
          </div>
          <div className="sg-registration-subheader-event-information sg-registration-large-copy">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <b>Event Information:</b>
                  <br/>
                  AT&T Pebble Beach Pro-Am
                  <br/>
                  Pebble Beach, CA
                  <br/>
                  <br/>
                </div>
                <div className="col-xs-12 col-sm-7" style={{paddingRight:"0"}}>
                  <b>
                    Cisco Million Dollar Hole-in-One for Charity
                  </b>
                  <br/>
                  <b>Wednesday,&nbsp;February&nbsp;1,&nbsp;2023</b>
                  <br/>
                  2:00 p.m. – 4:30 p.m. PT
                  <div style={{marginTop:"5px"}} className="sg-registration-small-copy">
                    <i>
                      Cisco reception immediately following at Hay’s Place.
                      <br/>
                      Practice Rounds open to the public at Pebble Beach Golf Links
                    </i>

                  </div>
                </div>
                <div className="col-xs-12 col-sm-5">
                  <b>
                     Tournament Rounds
                     <br/>
                    Thursday,&nbsp;February&nbsp;2 – Sunday,&nbsp;February&nbsp;5,&nbsp;2023
                  </b>
                   <br/>
                   8:30 a.m. – 5:00 p.m. PT
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="sg-registration-copy">
              Please complete the following ticket request form.
              <br/>
              <br/>
              Ticket requests are exclusively for you (or a member of your team) to host your registered customer/partner(s) or partner(s) at the 2023 AT&T Pebble Beach Pro-Am on the day(s) they will be in attendance. Tickets are allocated based on availability. 
              <br/>
              <br/>
              Once the completed form has been submitted, your ticket request will be reviewed. If your ticket request is approved, you will receive a ticket confirmation email.
              <br/>
              <br/>
              Note: If you have not completed this request form and received a ticket confirmation email, then
your attendance at the tournament is not guaranteed or confirmed.
              <br/>
              <br/>
              If you have questions regarding the registration process, please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20Acct%20Manager%20Ticket%20Request%20%7C%202023%20AT%26T%20Pebble%20Beach%20Pro-Am" target="_blank:">executiverelations@cisco.com</a>.
              <br/>
              <br/>
              <span className="sg-registration-small-copy">All fields are mandatory unless marked as optional.</span>
              <br/>
              <br/>
            </div>
            <br/>
          </div>

        </div>
      )
    }
  }

  renderHeaderSignifiers() {
    const { page } =this.state;
    return (
      <div className="text-center att-proam-registration-header-page-signifier-container">
        {[1,3,4].map(x=> (
          <div className={`att-proam-registration-header-page-signifier ${page == x ? "active" : ""}`}>
            <img
              src={`/images/pages/page-${x}${page == x ? '-active' : ""}.svg`}
            />
          </div>
        ))}
      </div>
    )
  }

  renderButtons(formikProps) {
    const { page } = this.state;
    if(page < 4) {
      return (
        <div className="registration-page-one container visible">
          <div className="row">
            <div className="col-xs-12">
              <br/>
            </div>
            <div className="col-xs-12">
              <button onClick={()=>this.nextPage(formikProps)} className="sg-round-button sg-primary-button">
                Submit
              </button>
            </div>

            <div className="col-xs-12">
              <br/>
              <br/>
            </div>
          </div>
        </div>

      )
    }
  }

  nextPage(formikProps = {}){
  const { page }  = this.state;
  switch(page) {
    case 1:
      if(!this.isPageOneValid(formikProps)){
        this.setState({
          pageOneError: "Please make sure all fields have been properly filled in",
          checkError: true
        })
        return;
      }else {
        //TODO send request
        formikProps.submitForm();
        return;
      }
      return;
    default:
      break;
  }
  window.scrollTo(0,0);
  this.setState({
    page: page + 1,
    checkError: false,
  })
}

submitRegistration(values) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var form_data = new FormData();
    this.setState({
      loading: true,
    })
    const {additionalDates} = this.state;
    if(values.user.programname) {
      const count = values.user.bringingguest== "True" ? "2" : "1"
      this.props.ticket_info.map(x=> {
        values.user[x.slug] = "0";
      })
      values.user[values.user.programname] = count;
    }

    fetch(`/ams`, {
      method: `POST`,
      redirect: "manual",
      body: JSON.stringify(values),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    }).then(response => {
      try {
        return response.json();
      }
      catch(e) {
        this.setState({
          loading: false,
          error: "An error occurred. Please try again later."
        })
      }
    })
    .then(json => {
      if (json.error == null) {
        window.scrollTo(0,0);
        this.setState({
          page: 4,
          loading: false,
        })
      } else {
        this.setState({
          fullPageError: json.error,
          loading: false,
        })
      }
    });
  }

  render() {
    return (
      <div className="registration-form">
        {this.renderTester()}
        <Formik
          initialValues={{
            user: {
              "ecfirstname": "",
              "eclastname": "",
              "ecnumber": "",
              "ecrelation": "",
              "photographagreement": "",
              "dietarypreference": "",
              "specialaccomodations": "",
              "privacyagreement": "",
              "customer_company": "",
              "customer_email": "",
              "customer_name_last": "",
              "customer_first_name": "",
              "programname": "",
              "contact_name_first": "",
              "contact_name_last": "",
              "contact_email": "",
              "contact_work_phone": "",
            },
          }}
          onSubmit={(values, actions) => {
            console.log({
              values
            })
            this.submitRegistration(values)

          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0"}}>
              {this.renderHeader(formikProps)}
              {this.renderPageOne(formikProps)}
              {this.renderPageFour(formikProps)}
              {this.renderButtons(formikProps)}
            </Form>
        )}/>
      </div>
    );
  }
}

export default AmRegistrationForm;
