import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime, parseFromTimeZone} from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"
import Table from "rc-table"
import Select from 'react-select'

class UsersDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      searchDate: null,
      searchType: null,
    }

    this.columns = [
      {
        title: "Session Title",
        dataIndex: "title",
        key: "title",
        width: 300,
        render: (data) => (<b>{data}</b>),
      },
      {
        title: "Session Type",
        dataIndex: "session_type",
        key: "session_type",
      },
      {
        title: 'Date',
        dataIndex: '',
        key: 'date_and_time',
        render: (data) => (<>{formatToTimeZone(data.date_and_time, 'MM-DD-YYYY',{timeZone: 'America/Los_Angeles'})}</> ),
      },
      {
        title: 'Start Time',
        dataIndex: '',
        key: 'date_and_time',
        render: (data) => (<>{formatToTimeZone(data.date_and_time, 'HH:mm (z)',{timeZone: 'America/Los_Angeles'})}</> ),
      },
      {
        title: "Session Code",
        dataIndex: "code",
        key: "code",
        width: 100,
      },
      {
        title: 'Attendance Report',
        dataIndex: '',
        key: 'operations',
        render: (data) => (<a href={`/admin/sessions/${data.uuid}/individual_session_attendance`}>Download</a>),
      },
      {
        title: 'Zoom Report',
        dataIndex: '',
        key: 'operations',
        render: (data) => (<a href={`/admin/sessions/${data.uuid}/report`}>Download</a>),
      },
    ];
  }

  renderSelect() {
    const {sessions} = this.props;
    const { searchType } = this.state;
    const options = [{value: null, label:"All"}].concat(sessions.map(x=>x.session_type).filter((x,i,t)=> t.indexOf(x) == i).map(x=> {return {value: x,label: x}}));
    const value = options.find(x=>x.value == searchType);
    return (
      <Select
        options={options}
        value={value ? value : {value: "", label:""}}
        onChange={(option) => {
          this.setState({
            searchType: option.value,
          })
        }}
      />
    )
  }

  getData() {
    let { sessions } = this.props;
    const { searchQuery,searchDate,searchType } = this.state;
    sessions =  sessions.filter(x =>
      x.data && x.data.zoom && x.data.zoom.id
    )

    if(searchDate) {

      sessions =  sessions.filter(x =>
        isSameDay(new Date(x.date_and_time), parseFromTimeZone(searchDate,{timeZone: 'America/Los_Angeles'}))
      )
    }
    if(searchType) {

      sessions =  sessions.filter(x =>
        x.session_type == searchType
      )
    }

    if(searchQuery && searchQuery.length > 0) {
      sessions = sessions.filter(x=>
        x.title.toLowerCase().includes(searchQuery.toLowerCase())
        ||
        x.code.toLowerCase().includes(searchQuery.toLowerCase())
        ||
        x.session_type.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    return sessions.sort((a,b) =>
      compareAsc( new Date(a.date_and_time), new Date(b.date_and_time) )
    )
  }

  getTotalZoomRooms() {
    const { sessions } = this.props;
    const sorted_sessions =  sessions.filter(x =>
      x.data && x.data.zoom && x.data.zoom.id
    ).map(x=> x.data.zoom.join_url).filter((x,i,t)=>t.indexOf(x) == i);
    return sorted_sessions.length
  }

  setDate(e) {
    this.setState({
      searchDate: e.target.value
    })
  }


  render() {
    const { searchQuery, searchDate } = this.state;
    return (
      <>
        <div className="row sg-platform-report-section">
          <div className="row">
            <div className="col-xs-12">
              <div className="sg-platform-report-circle">
                <div className="sg-platform-report-circle-inner">
                  <div className="sg-platform-report-circle-title">
                    Total Zoom
                    <br/> Rooms
                  </div>
                  <div className="sg-platform-report-circle-count">
                    {this.getTotalZoomRooms()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-xs-12 col-md-6">
              Search:
              <br />
              <input className="form-control" value={searchQuery} onChange={(e)=>this.setState({searchQuery: e.target.value})}/>
              <br />
            </div>
            <div className="col-xs-12 col-md-2">
              Filter by date {searchDate ? (<a href="#" onClick={()=>this.setState({searchDate: null})}>Clear</a>) : ""}
              <br />
              <input className="form-control" type="date" min="2021-02-21" max="2021-03-06" onChange={(e) => this.setDate(e)} value={searchDate} />
              <br />
            </div>
            <div className="col-xs-12 col-md-2">
              Filter by session type
              <br />
              {this.renderSelect()}
              <br />
            </div>
          </div>
          <table className="sg-admin-user-panel-table sg-admin-zoom-panel-table">
            <Table
              columns={this.columns}
              data={this.getData()}
              tableLayout="auto"
            />
          </table>
        </div>
      </>
    );
  }
}

export default UsersDashboard;
