import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import TimeSwitcher from "./TimeSwitcher";
import DateSwitcher from "./DateSwitcher";
import TimeSwitcherClock from "./TimeSwitcherClock";
import ProfileSwitcher from "./ProfileSwitcher";
import SwitcherReset from "./SwitcherReset";
import { formatToTimeZone } from 'date-fns-timezone'

class DropDownSwitcher extends React.Component {
  constructor(props) {
    super(props);
    const { times } = props;

    this.switcherValues = [
      {value: "0", label: formatToTimeZone(new Date(times.day_one_start), "MMM D", { timeZone: 'America/Los_Angeles' })},
      {value: "1", label: formatToTimeZone(new Date(times.day_two_start), "MMM D", { timeZone: 'America/Los_Angeles' })},
      {value: "2", label: formatToTimeZone(new Date(times.day_three_start), "MMM D", { timeZone: 'America/Los_Angeles' })},
      {value: "3", label: formatToTimeZone(new Date(times.day_four_start), "MMM D", { timeZone: 'America/Los_Angeles' })}
    ];
    let dayNumber = localStorage.getItem('dayNumber');

    const attendeeValues = [
      {value: "0", label: "GTM (SumoYou)"},
      {value: "1", label: "non-GTM (SKO)"},
      {value: "2", label: "AM Group"},
      {value: "3", label: "PM Group"}
    ];
    let profileNumber = localStorage.getItem('profileNumber');

    this.state = {
      modalOpen: false,
      dayNumber: dayNumber,
      dayTime: localStorage.getItem('time'),
      attendeeType: attendeeValues[profileNumber] ? attendeeValues[profileNumber].label : null,
      showClock: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleClock = this.toggleClock.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  refreshPage() {
    location.reload();
  }

  toggleClock() {
    this.setState({
      showClock: true
    });
  }

  renderModal() {
    const { times } = this.props;
    const { showClock } = this.state;
    return (
      <div className="row">
        <div className="sg-header-switchers">
          <div className="sg-header-reminder">
            Switchers used for testing purposes only.
          </div>
          <div className="sg-header-switchers-inner">
            <div className="sg-header-title-container sg-time-switcher">
              <div className="sg-header-time-switcher-wrapper">
                <div className="sg-header-time-switcher-clock">
                  <img src="/images/header/time_machine.svg" />
                </div>
                <div className="sg-header-time-switcher-select-wrapper" style={{width: "auto"}}>
                  <DateSwitcher times={times} toggleClock={this.toggleClock} />
                </div>
              </div>
            </div>
            <div className="sg-switcher-clock-wrapper">
              <TimeSwitcherClock clockIsOn={showClock} />
            </div>
            <div className="sg-header-title-container sg-profile-switcher">
              <div className="sg-header-time-switcher-wrapper">
                <div className="sg-header-time-switcher-clock">
                  <img src="/images/header/profile_switcher.svg" />
                </div>
                <div className="sg-header-time-switcher-select-wrapper">
                  <ProfileSwitcher />
                </div>
              </div>
              <div className="sg-header-social" style={{backgroundColor: "white"}}>
                <div className="sg-header-social-wrapper"/>
              </div>
            </div>

            <div className="sg-header-title-container sg-profile-switcher">
              <div className="sg-header-time-switcher-wrapper">
                <div className="sg-header-reset-wrapper">
                  <div className="sg-header-submit" onClick={this.refreshPage}>
                    Submit
                  </div>
                </div>
              </div>
            </div>

            <div className="sg-header-title-container sg-profile-switcher">
              <div className="sg-header-time-switcher-wrapper">
                <div className="sg-header-reset-wrapper">
                  <SwitcherReset />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  toTwelveHour(time) {
    let hours = time.substring(0, 2);
    let mins = time.substring(3,5);
    let ampm = "am";
    if (hours > 12) {
      hours = hours - 12;
      ampm = "pm"
    }
    return `${hours}:${mins} ${ampm}`;
  }

  renderDetails() {
    const { dayNumber, dayTime, attendeeType } = this.state;
    let now = new Date(Date.now());
    let nowHours = now.getHours();
    let nowMinutes = now.getMinutes();
    let nowTime = `${nowHours < 10 ? `0${nowHours}` : nowHours}:${nowMinutes < 10 ? `0${nowMinutes}` : nowMinutes}`;

    return (
      <div className="switcher-preview">
        {dayNumber !== null ? <>{dayNumber}</> : ""}
        {dayNumber !== null ?
          dayTime !== null ? `, ${this.toTwelveHour(dayTime)}` : `, ${this.toTwelveHour(nowTime)}`
          :
          ""
        }
        {attendeeType !== null ? <><br />{attendeeType}</> : ""}
      </div>
    )
    return "";
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <>
        <div onClick={this.toggleModal}>
          Preview Center
          {this.renderDetails()}
        </div>
        <Modal
          className="white-background sg-speaker-modal disable-scrollbars"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <div className="sg-overlay-close-button" onClick={this.toggleModal}> &#10005; </div>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default DropDownSwitcher;
