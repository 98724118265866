import React from "react";

class RegistrationPageSignifier extends React.Component {

  render() {
    const { page } = this.props;
    if(page==4){
      return (
        <div className="sg-registration-signifier-container">
          {[4].map(x=> (
            <div className={`sg-registration-signifier-page sg-registration-signifier-page-${x} ${page > x ? "passed" : ""} ${page == x ? "active" : ""}`}>
              <img
                src={`/registration/images/page-${x}.svg`}
              />
            </div>
          ))}
          </div>
      )
    }
    return (
      <div className="sg-registration-signifier-container">
        <div className="sg-registration-signifier-begin"/>
        {[1,2].map(x=> (
          <div className={`sg-registration-signifier-page sg-registration-signifier-page-${x} ${page > x ? "passed" : ""} ${page == x ? "active" : ""}`}>
            <img
              src={`/registration/images/page-${x}.svg`}
            />
          </div>
        ))}
        <div className="sg-registration-signifier-pill">
          <div className="sg-registration-signifier-pill-inner"/>
        </div>
        <div className="sg-registration-signifier-end"/>
      </div>
    )
  }

}

export default RegistrationPageSignifier;
