import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";

class UserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  renderModal() {
    const { user, attendee} = this.props;
    return (
      <div className="row">
        <div className="col-xs-12 sg-admin-user-modal-title">
          User Management
        </div>
        <div className="col-xs-12 col-md-6">
          <b>Email:</b> {user.email}
          <br/>
          <b>Name:</b> {attendee ? `${attendee.name_first} ${attendee.name_last}` : "Unavailable"}
          <br />
          <b>Attendee UUID:</b> {attendee ? attendee.uuid : "Unavailable"}
          <br/>
          <b>Test Flag:</b> {user.test_flag ? "true" : "false"}
          <br/>
          <b>Admin:</b> {user.admin ? "true" : "false"}
          <br/>
          <b>Unsubscribed from email:</b> {user.unsubscribed ? "true" : "false"}
        </div>
        <div className="col-xs-12 col-md-6">
          <b>Account Created:</b> {user.created_at}
          <br />
        </div>
      </div>
    );
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, sessions } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: "inline-block"}} onClick={() => this.toggleModal()} >
          {children}
        </div>

        <Modal
          className="white-background sg-speaker-modal disable-scrollbars sg-admin-user-modal"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <div className="sg-overlay-close-button" onClick={this.toggleModal}> &#10005; </div>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default UserModal;
