import React from "react";
import PropTypes from "prop-types";
import TicketDashboardBubble from "./TicketDashboardBubble"
class TicketsDashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  renderTickets() {
    const { ticketTypes } = this.props;
    return ticketTypes.sort((a,b)=>a.id - b.id).map(x=>
      this.renderTicketType(x)
    )
  }

  renderTicketType(ticket) {
    const { updateTicket, participants} = this.props;
    return (
      <TicketDashboardBubble
        ticket={ticket}
        participants={participants}
        updateTicket={updateTicket}
      />
    )
  }

  renderTotalTickets() {
    const { ticketTypes } = this.props;

    const totalTicketsAllocated = ticketTypes.map(ticket => {
      let count = ticket.tickets_registered;
      if (typeof count === "number" && !isNaN(count)) {
        return count;
      }
      return 0;
    }).reduce((a,b)=>(a||0)+(b||0), 0);

    const totalTicketsAvailable = ticketTypes.map(ticket => 
      parseInt(ticket.count)
    ).reduce((a,b)=>(a||0)+(b||0), 0);

    return (
      <div className="col-xs-6 col-sm-2">
        <div className="sg-tickets-dashboard-ticket-type-container tickets-dashboard-blue">
          <div className="sg-tickets-dashboard-ticket-type-name">
            Event Total
          </div>
          <div className="sg-ticket-dashboard-count-section">
            <div className="sg-tickets-dashboard-ticket-type-count">
              { totalTicketsAllocated }/{ totalTicketsAvailable }
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="sg-tickets-dashboard-container row">
        {this.renderTickets()}
        {this.renderTotalTickets()}
      </div>
    )
  }
}

export default TicketsDashboard;
